import moment from 'moment';
import React, { FC } from 'react';

const styles = `font-semibold outline-none bg-transparent`;
// Thies PLACEHOLDER added for content's jumping preventing
const PLACEHOLDER = <span className='opacity-0'>PLACEHOLDER</span>;

const renderTOOFormat = (reportTime?: number): string => {
    if (reportTime === undefined) {
        return '';
    }

    return moment(reportTime).utc().format('DDHHmm[Z]');
};

export const TimeOfObservation: FC<{ reportTime: number; }> = ({ reportTime }) => (
    <div className="pr-3">
        <label htmlFor="observation_time">
            Time of the observation
            <p className={styles}>{renderTOOFormat(reportTime) || PLACEHOLDER}</p>
            <input
                hidden
                readOnly
                id="observation_time"
                value={renderTOOFormat(reportTime)}
            />
        </label>
    </div>
);