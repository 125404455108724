export const ENLARGED_FEATURE_OFF = {
    status: false,
    visible: false,
    available: false,
};

export const ENLARGED_FEATURE_ON = {
    status: true,
    visible: true,
    available: true,
};

export const NEW_REPORT_ID = 'new-report-form';
