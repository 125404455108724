import React, { useState, FC, useEffect } from 'react';

import { Select } from 'pages/generic/form/select';

import { SelectAttributes, FormRegistering } from '../types';

type SettingsSelectProps = {
    id: string;
    inputAttr: SelectAttributes;
    formRegistering: FormRegistering;
};

export const SettingsSelect: FC<SettingsSelectProps> = ({
    id,
    inputAttr,
    formRegistering,
}) => {
    const { options, value } = inputAttr;
    const [selected, setSelected] = useState(value);
    const formValue = formRegistering.getValues()[id];
    useEffect(() => {
        setSelected(formValue as string | undefined);
    }, [formValue, setSelected, id, formRegistering]);

    return (
        <div className="flex flex-grow grow">
            <Select
                value={selected}
                className="w-full"
                options={options}
                onChange={(newValue) => {
                    setSelected(newValue);
                    formRegistering.setValue(id, newValue);
                }}
            />
        </div>
    );
};
