import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import LoadingSpinner from './LoadingSpinner';

const KeycloakLoadingScreen = (): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const timeout = setTimeout(() => {
            enqueueSnackbar(
                'Authentication server temporarily down, refresh page to connect again.',
                {
                    persist: true,
                    content: (key, message) => (
                        <div
                            role="alert"
                            className="p-3 rounded bg-awos-black-1 text-awos-gray-1 text-sm shadow-md dark:bg-awos-white-3 dark:text-awos-gray-15"
                        >
                            {message}
                            <button
                                type="button"
                                className="font-medium ml-10"
                                onClick={() => window.location.reload()}
                            >
                                Refresh
                            </button>
                        </div>
                    ),
                }
            );
        }, 10000);
        return () => clearTimeout(timeout);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="flex h-screen justify-center z-10 bg-c-statusbar-light dark:bg-c-statusbar-dark">
            <LoadingSpinner text="Loading Airotec AWOS" />
        </div>
    );
};

export default KeycloakLoadingScreen;
