import { get } from 'lodash';
import classNames from 'classnames';
import { useMemo, FC } from 'react';
import { Redirect } from 'react-router';
import { StyleSheet, css } from 'aphrodite';

import {
    useFeatureFlagsContext,
    FeatureFlagsContextProps,
} from 'tools/contexts/FeatureFlagsContext';
import { FeatureFlags } from 'consts';
import Table from 'pages/generic/Table';
import { ProtectFeatureByFlag } from 'tools/protectors';
import { useKeycloak } from 'tools/contexts/KeycloakContext';

import {
    useHomeDataContext,
    HomeDataContextProvider,
} from './standardStationHomeDataContextProvider';
import {
    SidePanel,
    FooterReport,
    ReportGenerationWidget,
    TelegramTable,
} from '../components';
import { createHomepageGrid } from './helpers';

import './StandardStationHome.css';

const getEmptyRosesClassName = (
    checkFlag: FeatureFlagsContextProps['checkFlag']
) =>
    !checkFlag(FeatureFlags.LEFT_RUNWAY) ||
    !checkFlag(FeatureFlags.RIGHT_RUNWAY)
        ? 'empty-rose'
        : 'non-empty-rose';

const createInlineStyles = (checkFlag: FeatureFlagsContextProps['checkFlag']) =>
    StyleSheet.create({
        homepageWrapperGrid: createHomepageGrid(checkFlag),
    });

const styles = {
    container: 'text-center grid m-5 sm:mx-10 md:mx-14 lg:mx-16',
};

const Home: FC = () => {
    const { keycloak } = useKeycloak();
    const {
        rvr,
        gas,
        clouds,
        weather,
        pressure,
        reportList,
        windroseLeft,
        windroseRight,
        validLastMetReport,
    } = useHomeDataContext();

    const { checkFlag } = useFeatureFlagsContext();
    const inlineStyles = useMemo(
        () => createInlineStyles(checkFlag),
        [checkFlag]
    );

    if (!keycloak?.authenticated) {
        return <Redirect to="/" />;
    }

    return (
        <div
            className={classNames(
                styles.container,
                getEmptyRosesClassName(checkFlag),
                'home-page-wrapper',
                css(inlineStyles.homepageWrapperGrid)
            )}
        >
            <ProtectFeatureByFlag flag={FeatureFlags.LEFT_RUNWAY}>
                <div
                    className={classNames('wind-rose', {
                        'active-rose': get(
                            windroseLeft,
                            'data.runway_active',
                            false
                        ),
                    })}
                >
                    <SidePanel windrose={windroseLeft} />
                </div>
            </ProtectFeatureByFlag>

            <ProtectFeatureByFlag flag={FeatureFlags.RIGHT_RUNWAY}>
                <div
                    className={classNames('wind-rose', {
                        'active-rose': get(
                            windroseRight,
                            'data.runway_active',
                            false
                        ),
                    })}
                >
                    <SidePanel windrose={windroseRight} />
                </div>
            </ProtectFeatureByFlag>

            <ProtectFeatureByFlag flag={FeatureFlags.PRESSURE_TABLE}>
                <div className="pressure-area">
                    <Table {...pressure} />
                </div>
            </ProtectFeatureByFlag>

            <ProtectFeatureByFlag flag={FeatureFlags.RVR_TABLE}>
                <div className="rvr-area">
                    <Table {...rvr} />
                </div>
            </ProtectFeatureByFlag>

            <ProtectFeatureByFlag flag={FeatureFlags.CLOUDS_TABLE}>
                <div className="cloud-area">
                    <Table {...clouds} />
                </div>
            </ProtectFeatureByFlag>

            <ProtectFeatureByFlag flag={FeatureFlags.WEATHER_TABLE}>
                <div className="weather-area">
                    <Table {...weather} />
                </div>
            </ProtectFeatureByFlag>

            <ProtectFeatureByFlag flag={FeatureFlags.GAS_TABLE}>
                <div className="gas-area">
                    <Table {...gas} className="gas-table" />
                </div>
            </ProtectFeatureByFlag>

            <ProtectFeatureByFlag flag={FeatureFlags.REPORTS}>
                <div className="create-report-area relative">
                    <ReportGenerationWidget
                        widgetName="REPORT"
                        isSyncFailed={validLastMetReport?.isSyncFailed}
                    >
                        <TelegramTable data={validLastMetReport?.data} />
                    </ReportGenerationWidget>
                </div>
            </ProtectFeatureByFlag>

            <ProtectFeatureByFlag flag={FeatureFlags.REPORTS}>
                <div className="check-report-area relative">
                    <FooterReport {...reportList} />
                </div>
            </ProtectFeatureByFlag>
        </div>
    );
};

export const StandardStationHome: FC = () => (
    <HomeDataContextProvider>
        <Home />
    </HomeDataContextProvider>
);
