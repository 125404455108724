import React, {
    FC,
    useRef,
    useState,
    useEffect,
    useCallback,
    ChangeEvent,
} from 'react';
import { debounce } from 'lodash';

import Input from 'pages/generic/form/Input';

import { InputAttributes, FormRegistering } from '../types';

type SettingsInputProps = {
    name: string;
    inputAttr: InputAttributes;
    formRegistering: FormRegistering;
};

export const SettingsInput: FC<SettingsInputProps> = ({
    name,
    inputAttr,
    formRegistering,
}: SettingsInputProps) => {
    const inputRef = useRef(null);
    const formValue = formRegistering.getValues()[name];
    const [inputValue, setInputValue] = useState<number | string>(
        inputAttr.value || ''
    );

    useEffect(
        () =>
            setInputValue(formRegistering.getValues()[name] as string | number),
        [formValue, setInputValue, name, formRegistering]
    );

    // eslint-disable-next-line
    const debouncedQueryChange = useCallback(
        debounce((value) => {
            const valueAsNumber = inputAttr.type === 'number';
            formRegistering.setValue(
                name,
                valueAsNumber ? Number(value) : value
            );
        }, 250),
        [setInputValue]
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        debouncedQueryChange(e.target.value);
    };

    return (
        <div className="flex flex-grow grow">
            <Input
                id={name}
                name={name}
                hideControls
                value={inputValue}
                type={inputAttr.type}
                step={inputAttr.step}
                componentRef={inputRef}
                onChange={handleChange}
                minLength={inputAttr.min_length}
                maxLength={inputAttr.max_length}
                placeholder={inputAttr.placeholder}
            />
        </div>
    );
};
