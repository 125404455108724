import {
    ArrayParam,
    NumberParam,
    StringParam,
    BooleanParam,
    useQueryParams,
} from 'use-query-params';
import { Redirect } from 'react-router';
import React, { useEffect } from 'react';

import { useGetData } from 'tools/hooks/getData';
import AwosIcon from 'pages/generic/AwosIcon';
import { useKeycloak } from 'tools/contexts/KeycloakContext';
import BasicWindowContainer from 'pages/generic/BasicWindowContainer';

import ReportTable from './components/ReportTable';
import { ActionBar } from './components/action-bar';
import { ReportsTableData } from './components/ReportTypes';

const styles = {
    container: 'flex flex-col m-5 sm:mx-10 md:mx-14 lg:mx-16',
    window: {
        reportIcon: 'inline w-4 py-1 mr-4',
        leftText:
            'flex content-center text-white font-semibold dark:text-awos-white-3',
    },
};

const Reports: React.FunctionComponent = () => {
    const { keycloak } = useKeycloak();

    const [queryParam] = useQueryParams({
        to_date_time: NumberParam,
        from_date_time: NumberParam,
        report_type: ArrayParam,
        per_page: NumberParam,
        page: NumberParam,
        desc_sorting: BooleanParam,
        search: StringParam,
    });

    const { data: reportsTableData, refetch } = useGetData<ReportsTableData>({
        initialValue: {
            reports: undefined,
            paging: undefined,
        },
        retry: true,
        enabled: false,
        endpoint: `/reports${window.location.search}`,
        queryName: 'reports',
    });

    useEffect(() => {
        refetch();
    }, [queryParam]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!keycloak?.authenticated) return <Redirect to="/" />;

    return (
        <div className={styles.container}>
            <BasicWindowContainer
                styles={{
                    container: 'border',
                }}
                topLeft={
                    <span className={styles.window.leftText}>
                        <AwosIcon
                            iconName="report"
                            styles={styles.window.reportIcon}
                        />
                        REPORT LIST
                    </span>
                }
            >
                <ActionBar />
                <ReportTable
                    reportsTableData={reportsTableData as ReportsTableData}
                />
            </BasicWindowContainer>
        </div>
    );
};

export default Reports;
