import { FormSchema } from '../../types';

const metForm: FormSchema = {
    formFields: [
        {
            title: 'Wind',
            id: 'surface_wind',
            placeHolderText: 'MAX28 MNM10 END 250/14KT',
            columnsWidth: 4,
            validationFieldName: 'surface_wind',
        },
        {
            title: 'Visibility',
            id: 'visibility',
            placeHolderText: 'VIS 500M',
            columnsWidth: 4,
            validationFieldName: 'visibility',
            cavokValue: '',
        },
        {
            title: 'RVR',
            id: 'rvr',
            placeHolderText: 'RVR RWY 19 650M',
            columnsWidth: 4,
            validationFieldName: 'rvr',
            cavokValue: '',
        },
        {
            title: 'Present weather',
            id: 'present_weather',
            placeHolderText: 'FBL SN BR',
            columnsWidth: 2,
            validationFieldName: 'present_weather',
            cavokValue: '',
        },
        {
            title: 'Cloud',
            id: 'cloud',
            placeHolderText: 'CLD OBSC VER VIS 150M',
            columnsWidth: 2,
            validationFieldName: 'cloud',
            cavokValue: '',
        },
        {
            title: 'Temperature',
            id: 'air_temperature',
            placeHolderText: 'TMS08',
            columnsWidth: 2,
            validationFieldName: 'air_temperature',
        },
        {
            title: 'Dew-point temperature',
            id: 'dew_point',
            placeHolderText: 'DPMS18',
            columnsWidth: 2,
            validationFieldName: 'dew_point',
        },
        {
            title: 'QNH',
            id: 'pressure_values',
            placeHolderText: 'QNH 1004HPA',
            columnsWidth: 2,
            validationFieldName: 'pressure_values',
        },
        {
            title: 'Recent weather',
            id: 'recent_weather',
            placeHolderText: 'RERA',
            columnsWidth: 2,
            validationFieldName: 'recent_weather',
        },
        {
            title: 'Significant meteorological phenomena',
            id: 'sig_met_phenomena',
            placeHolderText: 'WS IN APCH 60M-WIND 360/13MPS',
            columnsWidth: 4,
            validationFieldName: 'sig_met_phenomena',
        },
    ],
};

export default metForm;
