import { FC } from 'react';
import classNames from 'classnames';

const cardWrapperStyles = [
    'flex flex-col',
    'bg-white dark:bg-c-card-dark-bg',
    'text-awos-navy-2 dark:text-awos-white-3',
    'border rounded dark:border-c-card-dark-border border-c-card-light-border',
].join(' ');

const cardBodyStyle = [
    'p-4 min-h-8',
    'text-bold text-xl md:text-2xl',
    'flex flex-1 flex-col items-center justify-center',
].join(' ');

const cardFooterStyles = [
    'text-sm sm:text-base p-4',
    'flex items-center justify-center',
    'dark:bg-c-card-dark-footerBg bg-c-card-light-footerBg',
    'dark:border-c-card-dark-border border-t border-c-card-light-border',
].join(' ');

export interface CardI {
    title: string;
    value: string[];
    className?: string;
}

export const Card: FC<CardI> = ({ title, value, className }) => {
    return (
        <div
            key={`${title}${value.join()}`}
            className={classNames('card', className, cardWrapperStyles)}
        >
            <div className={classNames('card-body', cardBodyStyle)}>
                {value.map((valueStr) => (
                    <div key={valueStr}>{valueStr}</div>
                ))}
            </div>
            <div className={classNames('card-footer', cardFooterStyles)}>
                {title}
            </div>
        </div>
    );
};
