import React, { RefObject, useEffect } from 'react';

function isParentButton(target: HTMLElement): boolean {
    if (target.parentElement !== null) {
        if (
            target.localName === 'button' ||
            target.parentElement.localName === 'button'
        )
            return true;
        return isParentButton(target.parentElement);
    }

    return false;
}

function useClickAwayChecker(
    ref: RefObject<HTMLElement>,
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
): void {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            const eventTarget = event.target as HTMLElement;
            if (
                visible &&
                ref.current &&
                !ref.current.contains(eventTarget) &&
                !isParentButton(eventTarget)
            ) {
                setVisible(false);
            }
        }
        if (visible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, setVisible, visible]);
}

export default useClickAwayChecker;
