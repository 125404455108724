import { get } from 'lodash';
import React, { ReactElement, useState, useMemo } from 'react';

import { ReportType } from 'consts';
import { SyncFailedSpinner } from 'pages/generic/syncFailedSpinner';

import SVGIcon from 'assets/icons/SVGIcon';
import { ReactComponent as ChevronUpSVG } from 'assets/icons/svg/Chevron up_on.svg';
import { ReactComponent as ChevronDownSVG } from 'assets/icons/svg/Chevron down_on.svg';

type ReportFooterProps = {
    data?: ReportListData;
    isSyncFailed?: boolean;
};

export type ReportData = {
    data: string;
    icao: string;
    type: ReportType;
    timestamp: number;
};

export type ReportListData = {
    reports: ReportData[];
};

const formatReportText = (reportData?: ReportListData): string =>
    get(reportData, ['reports', 0, 'data'], '');

const FooterReport = ({
    data: reportList,
    isSyncFailed = false,
}: ReportFooterProps): ReactElement => {
    const [reportListShown, setShowReportList] = useState(false);
    const styles = useMemo(
        () => ({
            footer: `h-10 flex flex-row justify-between items-center border overflow-hidden text-sm sm:text-base font-semibold
        border-c-footer-light-border bg-c-footer-light-background text-c-footer-light-text
        dark:border-c-footer-dark-border dark:bg-c-footer-dark-background dark:text-c-footer-dark-text
            ${reportListShown ? 'rounded-b' : 'rounded'}`,
            footerText: `ml-2 sm:ml-6 text-sm md:text-base truncate`,

            button: 'flex items-center mr-2.5 text-left text-sm md:text-base font-semibold text-c-footer-light-buttonText dark:text-c-footer-dark-buttonText hover:bg-c-footer-light-buttonHover dark:hover:bg-c-footer-dark-buttonHover focus:outline-none',
            buttonIcon: `flex flex-col h-10 w-10 px-3.5 py-2.5 justify-between`,
            chevron: `transition-transform ease-in-out transform ${
                reportListShown ? 'rotate-180' : 'rotate-0'
            }`,
            buttonText: 'w-24 sm:w-28 md:w-32 text-left',

            extendedTableContainer: `duration-500 ease-in-out w-full absolute z-10 overflow-hidden border border-b-0 bottom-10 rounded-t text-left 
        border-c-footer-light-extendedBorder bg-c-footer-light-extendedBackground 
        dark:border-c-footer-dark-extendedBorder dark:bg-c-footer-dark-extendedBackground ${
            reportListShown ? 'max-h-112' : 'invisible max-h-0'
        }`,
            extendedTable: `mx-6 text-sm sm:text-base transition-opacity ease-in-out ${
                reportListShown ? 'opacity-100' : 'opacity-0'
            }`,
            extendedTableHeaderCell:
                'pb-3 font-normal text-c-footer-light-tableHeader dark:text-c-footer-dark-tableHeader',
            extendedTableCell:
                'pb-3 text-c-footer-light-text dark:text-c-footer-dark-text dark:text-opacity-70',
        }),
        [reportListShown]
    );

    const generateExtendedReportListCells = useMemo(
        () => (): JSX.Element[] =>
            get(reportList, 'reports', [])
                .map(
                    (report) =>
                        report && (
                            <tr
                                key={`footer-${report.data}-${report.timestamp}`}
                            >
                                <td className={styles.extendedTableCell}>
                                    {report.data}
                                </td>
                            </tr>
                        )
                )
                .reverse(),
        [reportList, styles]
    );

    const generateButton = useMemo(
        () => (): JSX.Element =>
            (
                <>
                    <SVGIcon
                        lightIcon={
                            <div className={styles.buttonIcon}>
                                <ChevronUpSVG
                                    className={styles.chevron}
                                    aria-hidden="true"
                                    focusable={false}
                                    role="img"
                                />
                                <ChevronDownSVG
                                    className={styles.chevron}
                                    aria-hidden="true"
                                    focusable={false}
                                    role="img"
                                />
                            </div>
                        }
                    />
                    <span className={styles.buttonText}>
                        {reportListShown ? 'SHOW LESS' : 'SHOW MORE'}
                    </span>
                </>
            ),
        [reportListShown, styles]
    );

    const formattedReportText = useMemo(
        () => formatReportText(reportList),
        [reportList]
    );

    return (
        <>
            <SyncFailedSpinner isSyncFailed={isSyncFailed} />
            <div className="relative">
                <div className={styles.extendedTableContainer}>
                    <table className={styles.extendedTable}>
                        <tbody>
                            <tr>
                                <th className={styles.extendedTableHeaderCell}>
                                    Last METARs
                                </th>
                            </tr>
                            {generateExtendedReportListCells()}
                        </tbody>
                    </table>
                </div>

                <div className={styles.footer}>
                    <div
                        className={styles.footerText}
                        title={formattedReportText}
                    >
                        {formattedReportText}
                    </div>

                    <button
                        className={styles.button}
                        type="button"
                        onClick={() => setShowReportList(!reportListShown)}
                    >
                        {reportList === undefined ? <></> : generateButton()}
                    </button>
                </div>
            </div>
        </>
    );
};

export { FooterReport };
