import React from 'react';
import { isNil } from 'lodash';

import AppRouter from 'routing/AppRouter';
import {
    useFeatureFlagsContext,
    FeatureFlagsContextProvider,
} from 'tools/contexts/FeatureFlagsContext';
import { useKeycloak } from 'tools/contexts/KeycloakContext';
import { useDarkMode } from 'tools/contexts/DarkModeContext';

import { StatusBar } from './statusBar';
import LoadingSpinner from './LoadingSpinner';

const LoadPage = (): JSX.Element => {
    const { darkMode, setDarkMode } = useDarkMode();
    const { initialized, keycloak } = useKeycloak();
    const { isLoading } = useFeatureFlagsContext();

    if (initialized && !isLoading && !isNil(keycloak.token)) {
        return (
            <>
                <FeatureFlagsContextProvider>
                    <StatusBar darkMode={darkMode} setDarkMode={setDarkMode} />
                    <AppRouter darkMode={darkMode} />
                </FeatureFlagsContextProvider>
            </>
        );
    }

    return (
        <div className="flex h-screen justify-center z-10 bg-c-statusbar-light dark:bg-c-statusbar-dark">
            <LoadingSpinner text="Loading Airotec AWOS" />
        </div>
    );
};

export default LoadPage;
