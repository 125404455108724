import React, {
    useState,
    Dispatch,
    useEffect,
    createRef,
    SetStateAction,
} from 'react';
import moment from 'moment';
import classNames from 'classnames';
import enGb from 'date-fns/locale/en-GB';
import InputMask from 'react-input-mask';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import { NumberParam, useQueryParam } from 'use-query-params';

import useClickAwayChecker from 'hooks/useClickAwayChecker';

import AwosIcon from '../../AwosIcon';
import TimePicker from '../TimePicker';
import CustomAlert from '../../CustomAlert';
import dateTimeStyles from './date-time.module.css';

registerLocale('en-gb', enGb);

type DateTimeProps = {
    className?: string;
    queryName: string;
    setDateTime?: Dispatch<SetStateAction<moment.Moment | undefined>>;
    minDate?: moment.Moment | undefined;
    disabled?: boolean;
};

const DateTime = ({
    className,
    queryName,
    setDateTime,
    minDate,
    disabled,
}: DateTimeProps): JSX.Element => {
    const getToday = (): moment.Moment => {
        const now = moment.utc();
        now.set('hour', 0);
        now.set('minute', 0);
        now.set('second', 0);
        return now;
    };

    const [popupVisible, setPopupVisible] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);
    const [dateValue, setDate] = useState(getToday());
    const [stringDate, setStringDate] = useState('');
    const [currentMonth, setCurrentMonth] = useState(getToday().month());

    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const [queryParam, setQueryParam] = useQueryParam(queryName, NumberParam);

    const validMinDate = (currentDate: moment.Moment): boolean => {
        if (minDate) {
            return currentDate.isSameOrAfter(minDate);
        }
        return true;
    };

    const handleSetHours = (newHour: number) => {
        setHours(newHour);
        setDate((oldState) => {
            const newState = oldState;
            newState.set('hour', newHour);
            if (validMinDate(newState)) {
                setStringDate(
                    moment(newState).utc(false).format('DD/MM/YYYY HH:mm:ss')
                );
                setQueryParam(moment(newState).unix());
                return newState;
            }
            return oldState;
        });
    };

    const handleSetMinutes = (newMinutes: number) => {
        setMinutes(newMinutes);
        setDate((oldState) => {
            const newState = oldState;
            newState.set('minute', newMinutes);
            if (validMinDate(newState)) {
                setStringDate(
                    moment(newState).utc(false).format('DD/MM/YYYY HH:mm:ss')
                );
                setQueryParam(moment(newState).unix());
                return newState;
            }
            return oldState;
        });
    };

    const handleSetSeconds = (newSeconds: number) => {
        setSeconds(newSeconds);
        setDate((oldState) => {
            const newState = oldState;
            newState.set('second', newSeconds);
            if (validMinDate(newState)) {
                setStringDate(moment(newState).format('DD/MM/YYYY HH:mm:ss'));
                setQueryParam(moment(newState).unix());
                return newState;
            }
            return oldState;
        });
    };

    useEffect(() => {
        if (queryParam) {
            const value = moment.unix(queryParam).utc();
            setDate(value);
            setHours(value.hour());
            setMinutes(value.minute());
            setSeconds(value.second());
            setCurrentMonth(value.month());
            setStringDate(moment(value).format('DD/MM/YYYY HH:mm:ss'));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (stringDate === '__/__/____ __:__:__' || stringDate === '') return;
        if (minDate && moment(minDate).isValid()) {
            if (dateValue.isBefore(minDate)) {
                setDate(() => {
                    const newState = minDate;
                    setQueryParam(moment(newState).unix());
                    setHours(minDate.hour());
                    setMinutes(minDate.minute());
                    setSeconds(minDate.second());
                    setCurrentMonth(minDate.month());
                    setStringDate(
                        moment(newState).format('DD/MM/YYYY HH:mm:ss')
                    );

                    return newState;
                });
            }
        }
    }, [dateValue, minDate, setQueryParam, stringDate]);

    useEffect(() => {
        if (setDateTime) {
            if (stringDate === '__/__/____ __:__:__' || stringDate === '') {
                setDateTime(undefined);
            } else {
                setDateTime(dateValue);
            }
        }
    }, [dateValue, setDateTime]); // eslint-disable-line react-hooks/exhaustive-deps

    const styles = {
        dateTimeContainer:
            ' relative inline-block group text-c-input-light-icon focus-within:text-c-input-light-iconFocus dark:text-c-input-dark-icon dark:focus-within:text-c-input-dark-iconFocus',
        //
        input: 'text-sm md:text-base  sm:w-48 md:w-52 lg:w-56 xl:w-60 p-2 pl-3 font-semibold outline-none border rounded border-c-input-border focus:border-c-input-borderFocus focus:ring text-c-input-text placeholder-awos dark:placeholder-awosDark placeholder-font-normal dark:text-awos-white-3 dark:bg-awos-grayscale-77 dark:ring-awos-gray-18 dark:border-awos-grayscale-46 dark:hover:border-awos-gray-17 dark:focus:border-awos-white-3 disabled:cursor-default disabled:bg-awos-gray-8 disabled:text-awos-gray-12 ',
        icon: 'absolute my-3 right-3 w-4 ',
        dateTimePopup: {
            container:
                ' absolute mt-1 sm:w-48 md:w-52 lg:w-56 xl:w-60 bg-white shadow-lg rounded-md border text-awos-navy-3 focus:outline-none dark:text-awos-white-3 dark:bg-awos-gray-15 dark:border-awos-grayscale-46',
            iconBox:
                'flex justify-center py-2 px-2.5 w-full h-full  block rounded hover:bg-awos-gray-1',
            arrowIcon:
                'block w-2 outline-none focus:outline-none text-awos-navy-2 dark:text-awos-white-3',
            tile: 'text-sm w-7 h-7 p-1 outline-none hover:text-awos-navy-2 hover:bg-awos-white-2 dark:hover:bg-awos-black-4',
            weekdayText:
                'text-xs font-medium text-awos-gray-10 dark:text-awos-gray-19',
        },
    };

    const handleDateChange = (val: Date): void => {
        setDate((oldState) => {
            const newState = moment(val).utc();
            newState.set('hour', oldState.utc().hour());
            newState.set('minute', oldState.minute());
            newState.set('second', oldState.second());
            if (validMinDate(newState)) {
                setStringDate(moment(newState).format('DD/MM/YYYY HH:mm:ss'));
                setQueryParam(moment(newState).unix());
                setCurrentMonth(val.getUTCMonth());
                return newState;
            }
            return oldState;
        });
    };

    const validUserInputDateTime = (value: string) => {
        if (value === '__/__/____ __:__:__' || value === '') {
            setStringDate(value);
            setCurrentMonth(getToday().toDate().getMonth());
            setHours(0);
            setMinutes(0);
            setSeconds(0);
            setDate(getToday);
            setQueryParam(undefined);
            return;
        }
        const gotDate = moment(value, 'DD/MM/YYYY HH:mm:ss', true);
        if (gotDate.isValid() && validMinDate(gotDate)) {
            setStringDate(moment(gotDate).format('DD/MM/YYYY HH:mm:ss'));
            setCurrentMonth(gotDate.toDate().getMonth());
            setHours(gotDate.hour());
            setMinutes(gotDate.minute());
            setSeconds(gotDate.second());
            setDate(gotDate);
            setQueryParam(gotDate.utc().unix());
        } else {
            setAlertVisible(true);
            setStringDate(moment.utc().format('DD/MM/YYYY HH:mm:ss'));
        }
    };

    const clickAwayWrapperRef = createRef<HTMLDivElement>();
    useClickAwayChecker(clickAwayWrapperRef, popupVisible, setPopupVisible);

    return (
        <div ref={clickAwayWrapperRef} className={styles.dateTimeContainer}>
            <AwosIcon iconName="calendar" styles={styles.icon} />
            <InputMask
                data-testid={`datepicker_input_${queryName}`}
                value={stringDate}
                onChange={(e) => setStringDate(e.currentTarget.value)}
                mask="99/99/9999 99:99:99"
                name="datetime_input"
                type="text"
                className={classNames(
                    styles.input,
                    className,
                    dateTimeStyles.dateTime
                )}
                placeholder="DD/MM/YYYY HH:MM:SS"
                onFocus={() => setPopupVisible(true)}
                onKeyDown={(event) =>
                    event.key === 'Enter' || event.key === 'Tab'
                        ? validUserInputDateTime(event.currentTarget.value)
                        : undefined
                }
                onBlur={(e) => validUserInputDateTime(e.currentTarget.value)}
                disabled={disabled}
            />
            <div
                tabIndex={-1}
                data-testid={`datepicker_container_${queryName}`}
                className={`${popupVisible ? '' : 'hidden'} ${
                    styles.dateTimePopup.container
                }`}
            >
                <DatePicker
                    showPopperArrow={false}
                    calendarClassName="datePicker"
                    locale="en-gb"
                    formatWeekDay={(w) => (
                        <span className={styles.dateTimePopup.weekdayText}>
                            {w[0]}
                        </span>
                    )}
                    selected={
                        new Date(
                            Date.UTC(
                                dateValue.year(),
                                dateValue.month(),
                                dateValue.date()
                            )
                        )
                    }
                    minDate={
                        minDate
                            ? new Date(
                                  Date.UTC(
                                      minDate.year(),
                                      minDate.month(),
                                      minDate.date(),
                                      minDate.utc(true).hours(),
                                      minDate.minute(),
                                      minDate.second()
                                  )
                              )
                            : new Date(0)
                    }
                    onMonthChange={(date) => setCurrentMonth(date.getMonth())}
                    dayClassName={(date) => {
                        let tmpClassName = styles.dateTimePopup.tile;

                        const tmpDate = moment(date);
                        tmpDate.set(
                            'hour',
                            dateValue.hours() - date.getTimezoneOffset() / 60
                        );
                        tmpDate.set('minute', dateValue.minute());
                        tmpDate.set('second', dateValue.second());

                        if (
                            date.getMonth() === currentMonth &&
                            validMinDate(tmpDate)
                        ) {
                            if (date.getDate() === dateValue.date()) {
                                tmpClassName += '';
                            } else {
                                tmpClassName +=
                                    ' text-awos-navy-2 dark:text-awos-white-3';
                            }
                        } else {
                            tmpClassName +=
                                ' text-awos-gray-10 dark:text-awos-gray-19 ';
                        }
                        return tmpClassName;
                    }}
                    previousMonthButtonLabel={
                        <div className={styles.dateTimePopup.iconBox}>
                            <AwosIcon
                                iconName="chevron-left"
                                styles={styles.dateTimePopup.arrowIcon}
                            />
                        </div>
                    }
                    nextMonthButtonLabel={
                        <div className={styles.dateTimePopup.iconBox}>
                            <AwosIcon
                                iconName="chevron-right"
                                styles={styles.dateTimePopup.arrowIcon}
                            />
                        </div>
                    }
                    onChange={handleDateChange}
                    inline
                />
                <TimePicker
                    hours={hours}
                    setHours={handleSetHours}
                    minutes={minutes}
                    setMinutes={handleSetMinutes}
                    seconds={seconds}
                    setSeconds={handleSetSeconds}
                    selectedDate={dateValue}
                    minDate={minDate}
                />
            </div>
            <CustomAlert
                isModalShown={alertVisible}
                handleClose={() => setAlertVisible(false)}
            >
                Wrong date, please input valid date.
            </CustomAlert>
        </div>
    );
};

DateTime.defaultProps = {
    className: '',
    setDateTime: undefined,
    minDate: undefined,
    disabled: false,
};

export { DateTime };
