import classNames from 'classnames';
import 'rc-collapse/assets/index.css';
import React, { FC, useMemo } from 'react';
import RCollapse, { Panel as RPanel } from 'rc-collapse';

import AwosIcon from 'pages/generic/AwosIcon';

import './styles.css';
import { collapseMotion } from './animation';
import { CollapseProps, CollapsePanelProps } from './types';

const styleClassNames = {
    dropdownIcon: `dropdown-icon w-4`,
    extraContainer: `flex flex-row items-center`,
    iconContainer: `
        collapse-arrow flex items-center pl-2 ml-2 h-6 
        text-c-collapse-light-iconColor dark:text-c-collapse-dark-iconColor
        border-c-collapse-light-iconBorder dark:border-c-collapse-dark-iconBorder`,
    panel: `bg-c-collapse-light-panelBg dark:bg-c-collapse-dark-panelBg px-4 transition-height ease-in-out`,
    panelHeader: `
        flex justify-between 
        bg-c-collapse-light-headerBg dark:bg-c-collapse-dark-headerBg
        text-c-collapse-light-headerColor dark:text-c-collapse-dark-headerColor`,
    panelChildren: `
        border-t
        border-c-collapse-light-panelChildrenBr dark:border-c-collapse-dark-panelChildrenBr
        bg-c-collapse-light-panelChildrenBg dark:bg-c-collapse-dark-panelChildrenBg`,
};

export const Collapse: FC<CollapseProps> = ({
    className,
    defaultActiveKey = ['0'],
    openMotion = collapseMotion,
    ...restProps
}) => {
    return (
        <RCollapse
            {...restProps}
            openMotion={openMotion}
            defaultActiveKey={defaultActiveKey}
            className={classNames(className, 'awos-rc-collapse')}
        />
    );
};

export const Panel: FC<CollapsePanelProps> = ({
    extra,
    children,
    className,
    headerClass,
    ...restProps
}) => {
    const decoratedExtra = useMemo(() => {
        return (
            <div className={styleClassNames.extraContainer}>
                {extra}
                <span className={styleClassNames.iconContainer}>
                    <AwosIcon
                        styles={styleClassNames.dropdownIcon}
                        iconName="chevron-down"
                    />
                </span>
            </div>
        );
    }, [extra]);

    return (
        <RPanel
            {...restProps}
            showArrow={false}
            className={className}
            extra={decoratedExtra}
            headerClass={classNames(headerClass, styleClassNames.panelHeader)}
        >
            <div className={styleClassNames.panel}>
                <div className={classNames(styleClassNames.panelChildren)}>
                    {children}
                </div>
            </div>
        </RPanel>
    );
};
