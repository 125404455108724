import React, { FC } from 'react';
import {
    Tooltip as MUITooltop,
    TooltipProps as MUITooltipProps,
} from '@mui/material';

type TooltipProps = {
    childrenOnly?: boolean;
} & MUITooltipProps;

export const Tooltip: FC<TooltipProps> = ({
    children,
    childrenOnly = false,
    ...restProps
}) => {
    if (childrenOnly) {
        return <>{React.cloneElement(children, { title: undefined })}</>;
    }

    return (
        <MUITooltop
            {...restProps}
            arrow
            placement="top"
            classes={{
                tooltip: 'bg-awos-black-1 dark:bg-awos-black-15 rounded p-2',
                arrow: 'text-awos-black-1 dark:text-awos-black-15',
            }}
        >
            {React.cloneElement(children, { title: undefined })}
        </MUITooltop>
    );
};
