import { CSSProperties } from 'aphrodite';

import { FeatureFlags } from 'consts';
import { FeatureFlagsContextProps } from 'tools/contexts/FeatureFlagsContext';

type CheckedFlags = { [key in FeatureFlags]?: boolean };

const HOMEPAGE_AREAS: { [key: string]: string } = {
    [FeatureFlags.PRESSURE_TABLE]: 'pressure-area',
    [FeatureFlags.LEFT_RUNWAY]: 'first-rose',
    [FeatureFlags.RIGHT_RUNWAY]: 'second-rose',
    [FeatureFlags.RVR_TABLE]: 'rvr-area',
    [FeatureFlags.CLOUDS_TABLE]: 'cloud-area',
    [FeatureFlags.WEATHER_TABLE]: 'weather-area',
    [FeatureFlags.GAS_TABLE]: 'gas-area',
    [FeatureFlags.REPORTS_CREATE]: 'create-report-area',
    [FeatureFlags.REPORTS_CHECK]: 'check-report-area',
};

const flagsToCheck = [
    FeatureFlags.RVR_TABLE,
    FeatureFlags.WEATHER_TABLE,
    FeatureFlags.CLOUDS_TABLE,
    FeatureFlags.GAS_TABLE,
    FeatureFlags.PRESSURE_TABLE,
    FeatureFlags.LEFT_RUNWAY,
    FeatureFlags.RIGHT_RUNWAY,
    FeatureFlags.REPORTS_CREATE,
    FeatureFlags.REPORTS_CHECK,
];

const transfornStringToGridFormat = (str: string): string => `"${str}"`;
const repeatStringForGridFormat = (str: string, times: number): string =>
    new Array(times).fill(str).join(' ');

const createSmallGrid = (checkedFlags: CheckedFlags): string =>
    Object.entries(checkedFlags)
        .reduce<string[]>((result, [flag, isEnabled]) => {
            let gridString;
            if (isEnabled) {
                gridString = HOMEPAGE_AREAS[flag];

                if (flag === FeatureFlags.RIGHT_RUNWAY) {
                    gridString = checkedFlags[FeatureFlags.LEFT_RUNWAY] // handle case when only one rose is active
                        ? HOMEPAGE_AREAS[FeatureFlags.RIGHT_RUNWAY]
                        : HOMEPAGE_AREAS[FeatureFlags.LEFT_RUNWAY];
                }
            }

            if (gridString) {
                result.push(transfornStringToGridFormat(gridString));
            }
            return result;
        }, [])
        .join(' ');

const createMediumGrid = (checkedFlags: CheckedFlags) =>
    Object.entries(checkedFlags)
        .reduce<string[]>((result, [flag, isEnabled]) => {
            let gridString;
            if (isEnabled) {
                gridString = repeatStringForGridFormat(HOMEPAGE_AREAS[flag], 2);

                if (flag === FeatureFlags.RIGHT_RUNWAY) {
                    const isLeftEnabled =
                        checkedFlags[FeatureFlags.LEFT_RUNWAY];
                    if (isLeftEnabled) {
                        result.pop(); // we need replace previous string with new one
                        gridString = `${
                            HOMEPAGE_AREAS[FeatureFlags.LEFT_RUNWAY]
                        } ${HOMEPAGE_AREAS[FeatureFlags.RIGHT_RUNWAY]}`;
                    } else {
                        // handle case when only one rose is active
                        gridString = repeatStringForGridFormat(
                            HOMEPAGE_AREAS[FeatureFlags.LEFT_RUNWAY],
                            2
                        );
                    }
                }
            }

            if (gridString) {
                result.push(transfornStringToGridFormat(gridString));
            }
            return result;
        }, [])
        .join(' ');

const createLargeGrid = (checkedFlags: CheckedFlags) => {
    const isLeftRoseEnabled = checkedFlags[FeatureFlags.LEFT_RUNWAY];
    const isRightRoseEnabled = checkedFlags[FeatureFlags.RIGHT_RUNWAY];
    const areRosesDisabled = !isLeftRoseEnabled && !isRightRoseEnabled;
    const {
        left_runway: leftRose,
        right_runway: rightRose,
        ...tables
    } = checkedFlags;

    return Object.entries(tables)
        .reduce<string[]>((result, [flag, isEnabled]) => {
            let gridString;
            if (isEnabled) {
                if (areRosesDisabled || flag === FeatureFlags.REPORTS_CHECK) {
                    // we do not need roses area when roses are disabled or it is report_check area
                    gridString = repeatStringForGridFormat(
                        HOMEPAGE_AREAS[flag],
                        3
                    );
                } else if (!isLeftRoseEnabled) {
                    // we need only right rose area when left is disabled
                    gridString = `${repeatStringForGridFormat(
                        HOMEPAGE_AREAS[flag],
                        2
                    )} ${HOMEPAGE_AREAS[FeatureFlags.LEFT_RUNWAY]}`;
                } else if (!isRightRoseEnabled) {
                    // we need only left rose area when right  is disabled
                    gridString = `${
                        HOMEPAGE_AREAS[FeatureFlags.LEFT_RUNWAY]
                    } ${repeatStringForGridFormat(HOMEPAGE_AREAS[flag], 2)}`;
                } else if (isLeftRoseEnabled && isRightRoseEnabled) {
                    // when both roses present
                    gridString = `${HOMEPAGE_AREAS[FeatureFlags.LEFT_RUNWAY]} ${
                        HOMEPAGE_AREAS[flag]
                    } ${HOMEPAGE_AREAS[FeatureFlags.RIGHT_RUNWAY]}`;
                }
            }

            if (gridString) {
                result.push(transfornStringToGridFormat(gridString));
            }
            return result;
        }, [])
        .join(' ');
};

const createHomepageGrid = (
    checkFlag: FeatureFlagsContextProps['checkFlag']
): CSSProperties => {
    const checkedFlags: CheckedFlags = flagsToCheck.reduce<CheckedFlags>(
        (result, flag: FeatureFlags) => {
            let value = checkFlag(flag);

            if (
                flag === FeatureFlags.REPORTS_CREATE ||
                flag === FeatureFlags.REPORTS_CHECK
            ) {
                value = checkFlag(FeatureFlags.REPORTS);
            }

            return {
                ...result,
                [flag]: value,
            };
        },
        {}
    );

    return {
        gridTemplateAreas: createSmallGrid(checkedFlags),
        '@media (min-width: 700px)': {
            gridTemplateAreas: createMediumGrid(checkedFlags),
        },
        '@media (min-width: 1100px)': {
            gridTemplateAreas: createLargeGrid(checkedFlags),
        },
    };
};

export { createHomepageGrid };
