import React, { useCallback, FC } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';

import { Select, SelectProps } from './select';

interface QuerySelectProps extends SelectProps {
    queryName: string;
}

const QuerySelect: FC<QuerySelectProps> = ({
    options,
    className,
    queryName,
    disabled,
}) => {
    const [queryParam, setQueryValue] = useQueryParam(
        queryName ?? '',
        withDefault(StringParam, String(options[0].value))
    );

    const onChange = useCallback(
        (queryValue) => {
            setQueryValue(String(queryValue));
        },
        [setQueryValue]
    );

    return (
        <Select
            className={className}
            options={options}
            value={queryParam}
            onChange={onChange}
            disabled={disabled}
        />
    );
};

export { QuerySelect };
