import { useSnackbar } from 'notistack';
import { isEmpty, isNil } from 'lodash';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { isModalWindow } from 'helpers';
import { useKeycloak } from 'tools/contexts/KeycloakContext';
import { NotificationReason } from 'consts';

import {
    NotificationType,
    ReportModalConfiguration,
    ReportNotifyContextProps,
    ReportNotifyProviderProps,
} from './types';
import {
    DEFAULT_REPORT_MODAL_CONFIG,
    DEFAULT_REPORT_NOTIFICATION_CONTEXT,
} from './consts';
import { getSnackContent, transpileMessageData } from './helpers';

const ReportNotifyContext = createContext<ReportNotifyContextProps>(
    DEFAULT_REPORT_NOTIFICATION_CONTEXT
);

export type WebsocketMessage = {
    message_type?: string;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    data?: any;
};

export const ReportNotifyContextProvider = ({
    children,
    queue = [],
}: ReportNotifyProviderProps): JSX.Element => {
    const { keycloak, tokenRefreshed, setTokenRefreshed, checkRoles } =
        useKeycloak();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [reportPending, setReportPending] = useState<boolean>(false);
    const [websocket, setWebsocket] = useState<WebSocket | undefined>();
    const [reportsQueue, updateReportsQueue] =
        useState<NotificationType[]>(queue);
    const [reportModalConfig, setReportModalConfig] =
        useState<ReportModalConfiguration>(DEFAULT_REPORT_MODAL_CONFIG);
    const [isMainTab, setTabToMain] = useState({
        currMain: false,
        prevMain: false,
    });

    useEffect(() => {
        if (isNil(websocket) && !isModalWindow()) {
            const ws = new WebSocket(
                `wss://${window.location.host}/api/reports/ws`
            );
            ws.onclose = () => {
                setWebsocket(undefined);
                setTokenRefreshed(true);
            };
            setWebsocket(ws);
        }
    }, [websocket, setWebsocket, setTokenRefreshed]);

    useEffect(() => {
        if (tokenRefreshed && !isNil(websocket)) {
            if (websocket.OPEN === websocket.readyState) {
                const tokenMessage: WebsocketMessage = {
                    message_type: 'token',
                    data: {
                        token: keycloak.token ?? '',
                    },
                };
                websocket.send(JSON.stringify(tokenMessage));
                setTokenRefreshed(false);
            }
        }
    }, [
        websocket,
        websocket?.readyState,
        keycloak,
        tokenRefreshed,
        setTokenRefreshed,
    ]);

    useEffect(() => {
        if (websocket !== undefined) {
            websocket.onmessage = (event) => {
                const webSocketData = transpileMessageData(
                    JSON.parse(event.data)
                );

                const { reportType, notificationReason } = webSocketData;
                const reportId = enqueueSnackbar(<></>, {
                    style: {
                        ...{
                            // eslint-disable-next-line
                            opacity: !reportType
                                ? 1
                                : isEmpty(reportsQueue) && !reportPending
                                ? 0
                                : 1,
                        },
                    },
                    persist:
                        notificationReason !==
                        NotificationReason.REPORT_CREATED,
                    content: () => getSnackContent({ webSocketData }),
                });

                if (notificationReason !== NotificationReason.REPORT_CREATED) {
                    updateReportsQueue((currentQueue) => {
                        return [
                            ...currentQueue,
                            { ...webSocketData, reportId },
                        ];
                    });
                }
            };
        }
    }, [
        websocket,
        reportsQueue,
        reportPending,
        checkRoles,
        closeSnackbar,
        enqueueSnackbar,
        updateReportsQueue,
    ]);

    return (
        <ReportNotifyContext.Provider
            value={{
                isMainTab,
                websocket,
                setTabToMain,
                reportsQueue,
                reportPending,
                setReportPending,
                reportModalConfig,
                updateReportsQueue,
                setReportModalConfig,
            }}
        >
            {children}
        </ReportNotifyContext.Provider>
    );
};

export const useReportNotify = (): ReportNotifyContextProps =>
    useContext(ReportNotifyContext);
