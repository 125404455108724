import React, { useRef, useEffect, FC } from 'react';

import notificationSoundPath from 'assets/Sounds/elegant-notification-sound.mp3';

const notifyBySound = (
    snackBarContent: HTMLDivElement | null,
    mutedContainerClassName: string
) => {
    const notificationSound = new Audio(notificationSoundPath);
    notificationSound.addEventListener('canplaythrough', () =>
        notificationSound.play().catch(() => {
            const mutedNotificationContainer =
                snackBarContent &&
                snackBarContent.querySelector(`.${mutedContainerClassName}`);
            if (mutedNotificationContainer) {
                mutedNotificationContainer.textContent = '(notification muted)';
            }
        })
    );
};

export const SoundNotification: FC<{ mutedContainerClassName?: string }> = ({
    children,
    mutedContainerClassName = 'muted-notification',
}) => {
    const elemRef: React.RefObject<HTMLDivElement> = useRef(null);
    const wrapedChildren = <div ref={elemRef}>{children}</div>;
    useEffect(() => {
        notifyBySound(elemRef.current, mutedContainerClassName);
    }, [elemRef, mutedContainerClassName]);

    return wrapedChildren;
};
