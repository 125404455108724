import React, { FC } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { Roles } from 'consts';
import { useKeycloak } from 'tools/contexts/KeycloakContext';

type ProtectRouteByRoleProps = {
    path: string;
    roles: {
        included?: Roles[];
        exluded?: Roles[];
    };
    children: JSX.Element | JSX.Element[];
};
export const ProtectRouteByRole: FC<ProtectRouteByRoleProps> = ({
    path,
    children,
    roles = {},
}) => {
    const { checkRoles } = useKeycloak();

    if (checkRoles(roles)) {
        return <Route path={path}>{children}</Route>;
    }

    return <Redirect to="/" />;
};
