import React, { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import moment from 'moment';
import AwosIcon from '../AwosIcon';

type NumberSelectorProps = {
    testid?: string;
    className: string;
    min: number;
    max: number;
    value: number;
    setValue: React.Dispatch<number>;
};

const NumberSelector = ({
    testid,
    className,
    min,
    max,
    value,
    setValue,
}: NumberSelectorProps) => {
    const ref = useRef(null);

    const [input, setInput] = useState<string>(String(value));
    const [typing, setTyping] = useState<boolean>(false);

    useEffect(() => {
        setInput(value >= 10 ? String(value) : `0${value}`);
    }, [value]);

    const styles = {
        numberContainer: 'flex flex-col w-16 py-3',
        numberInput:
            'p-2 text-center font-semibold outline-none border rounded border-c-input-border focus:ring focus:border-c-input-borderFocus text-c-input-text placeholder-awos placeholder-font-normal dark:bg-awos-grayscale-77 dark:border-awos-grayscale-46 dark:text-awos-white-3 ',
        upChevron:
            'mb-2 w-3 ml-6 cursor-pointer text-awos-navy-2 dark:text-awos-white-3 ',
        downChevron:
            'mt-2 w-3 ml-6 cursor-pointer text-awos-navy-2 dark:text-awos-white-3',
    };

    const incrementValue = () => {
        if (value >= max - 1) {
            setValue(min);
        } else {
            setValue(value + 1);
        }
    };

    const decrementValue = () => {
        if (value <= min) {
            setValue(max - 1);
        } else {
            setValue(value - 1);
        }
    };

    const propperInputValue = (i: string) => {
        if (typing) return i;
        if (i === '') return '';
        if (Number(i) >= 10) return i;
        return `0${Number(i)}`;
    };

    return (
        <div className={styles.numberContainer}>
            <button
                tabIndex={-1}
                data-testid={`${testid}_up`}
                type="button"
                onClick={() => incrementValue()}
                className="pt-1"
            >
                <AwosIcon iconName="chevron-up" styles={styles.upChevron} />
            </button>
            <InputMask
                data-testid={testid}
                ref={ref}
                type="text"
                value={propperInputValue(input)}
                onChange={(e) => setInput(e.target.value)}
                onFocus={() => {
                    setTyping(true);
                    setInput('');
                }}
                className={styles.numberInput + className}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        event.currentTarget.blur();
                        setTyping(false);
                    }
                }}
                onBlur={() => {
                    if (input === '')
                        setInput(propperInputValue(String(value)));
                    else {
                        const inputValue = Number(input);
                        if (inputValue < max && inputValue >= 0) {
                            setValue(inputValue);
                        } else {
                            setValue(0);
                            setInput('00');
                        }
                    }
                    setTyping(false);
                }}
                mask="99"
                max={max}
            />
            <button
                tabIndex={-1}
                data-testid={`${testid}_down`}
                type="button"
                onClick={() => decrementValue()}
                className="pb-1"
            >
                <AwosIcon iconName="chevron-down" styles={styles.downChevron} />
            </button>
        </div>
    );
};

NumberSelector.defaultProps = {
    testid: '',
};

type TimePickerProps = {
    className?: string;
    hours: number;
    setHours: (newHour: number) => void;
    minutes: number;
    setMinutes: (newMinutes: number) => void;
    seconds: number;
    setSeconds: (newSeconds: number) => void;
    selectedDate: moment.Moment | undefined;
    minDate: moment.Moment | undefined;
};

const TimePicker = ({
    className,
    hours,
    setHours,
    minutes,
    setMinutes,
    seconds,
    setSeconds,
    selectedDate,
    minDate,
}: TimePickerProps): JSX.Element => {
    const styles = {
        inputContainer:
            'flex mx-2 justify-between group text-c-input-icon focus-within:text-c-input-iconFocus',
        divider: 'text-awos-gray-10 dark:text-awos-gray-19 self-center',
    };

    const getMinValue = (unitOfTime: moment.unitOfTime.DurationConstructor) => {
        if (minDate) {
            if (selectedDate?.isSameOrBefore(minDate)) {
                return minDate.get(unitOfTime);
            }
        }
        return 0;
    };

    return (
        <div className={styles.inputContainer}>
            <NumberSelector
                testid="timepicker_hours"
                className={className || ''}
                min={getMinValue('hour')}
                max={24}
                value={hours}
                setValue={setHours}
            />
            <span className={styles.divider}>:</span>
            <NumberSelector
                testid="timepicker_minutes"
                className={className || ''}
                min={getMinValue('minute')}
                max={60}
                value={minutes}
                setValue={setMinutes}
            />
            <span className={styles.divider}>:</span>
            <NumberSelector
                testid="timepicker_seconds"
                className={className || ''}
                min={getMinValue('second')}
                max={60}
                value={seconds}
                setValue={setSeconds}
            />
        </div>
    );
};

TimePicker.defaultProps = {
    className: '',
};

export default TimePicker;
