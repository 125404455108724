import { debounce } from 'lodash';
import classNames from 'classnames';
import { useQueryParam, StringParam } from 'use-query-params';
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';

import Input from './Input';
import AwosIcon from '../AwosIcon';

type QueryInputProps = {
    queryName: string;
    className?: string;
    disabled?: boolean;
    placeholder?: string;
};

const QueryInput = ({
    queryName,
    className = '',
    disabled = false,
    placeholder = '',
}: QueryInputProps): JSX.Element => {
    const styles = {
        inputContainer:
            'relative text-c-input-light-icon focus-within:text-c-input-light-iconFocus dark:text-c-input-dark-icon dark:focus-within:text-c-input-dark-iconFocus',
        searchIcon: 'absolute top-2/4 -mt-2 w-4 right-2',
    };

    const inputRef = useRef(null);
    const [inputQuery, setInputQuery] = useQueryParam(queryName, StringParam);
    const [inputValue, setInputValue] = useState(inputQuery || '');

    // eslint-disable-next-line 
    const debouncedQueryChange = useCallback(debounce((value) => {
        setInputQuery(value)
    }, 250), [setInputQuery]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        debouncedQueryChange(e.target.value);
    };

    return (
        <div className={classNames(styles.inputContainer, className)}>
            <Input
                type="text"
                iconPadding
                value={inputValue}
                disabled={disabled}
                componentRef={inputRef}
                onChange={handleChange}
                dataTestId="search_input"
                placeholder={placeholder}
            />
            <AwosIcon iconName="search" styles={styles.searchIcon} />
        </div>
    );
};

export default QueryInput;
