import React, { ChangeEvent } from 'react';
import AwosIcon from '../AwosIcon';

type CheckboxProps = {
    id: string;
    dataTestId: string;
    checked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox: React.FunctionComponent<CheckboxProps> = ({
    id,
    dataTestId,
    checked,
    onChange,
}: CheckboxProps) => {
    return (
        <div>
            <input
                type="checkbox"
                id={id}
                data-testid={dataTestId}
                checked={checked}
                className="hidden"
                onChange={(event) => onChange(event)}
            />
            <div
                className={`w-4 h-4 border rounded border-awos-grayscale-15 ${
                    checked
                        ? 'bg-awos-navy-2 dark:bg-awos-white-3 dark:border-awos-white-3'
                        : 'bg-white dark:bg-awos-gray-15 dark:border-awos-grayscale-92'
                }`}
            >
                <AwosIcon
                    iconName="checkmark"
                    styles={`w-3.5 h-3.5 stroke-current text-white dark:text-awos-gray-15 ${
                        checked
                            ? ''
                            : 'hover:text-awos-navy-2 dark:hover:text-awos-gray-22'
                    }`}
                />
            </div>
        </div>
    );
};

export default Checkbox;
