import React, { FC } from 'react';

import { ReportType } from 'consts';

import { Form } from '../forms';
import { ENLARGED_FEATURE_OFF } from '../consts';
import { metSchema } from '../forms/ReportSchemas';
import { ReportNotificationMessage } from '../forms/components';
import { EnlargedFeature, ReportModalConfiguration } from '../types';

type MetReportProps = {
    special?: EnlargedFeature;
    onReportDiscard: () => void;
    config: ReportModalConfiguration;
};

const DEFAULT_SPECIAL = {...ENLARGED_FEATURE_OFF };

export const MetReport: FC<MetReportProps> = ({
    config,
    onReportDiscard,
    special = DEFAULT_SPECIAL,
}) => (
    <>
        <ReportNotificationMessage message={config.data} />   
        <Form
            key="met"
            special={special}
            formSchema={metSchema}
            reportType={ReportType.MET}
            closeFormHandler={onReportDiscard}
        />
    </>

);
