import { useForm } from 'react-hook-form';
import React, { useEffect, FC } from 'react';

import { ReportType } from 'consts';
import { RadioInput } from 'pages/generic/form/Radio';

import { MetReport } from './MetReport';
import { MetarReport } from './MetarReport';
import { ENLARGED_FEATURE_ON } from '../consts';
import { ReportModalConfiguration } from '../types';

type FormProps = {
    onReportDiscard: () => void;
    config: ReportModalConfiguration;
};

export const CombineReport: FC<FormProps> = ({ onReportDiscard, config }) => {
    const formRegistering = useForm({
        defaultValues: {
            reportType: config.reportType,
        } as { [key: string]: string },
    });

    const { watch, reset } = formRegistering;

    const watchReportType = watch('reportType');

    useEffect(() => {
        reset();
    }, [config.reportId, reset]);

    function renderReportTypeInputs() {
        const types = [ReportType.METAR, ReportType.MET];
        return types.map((value) => {
            let label = value as string;
            const lowerLabel = label.toLowerCase();
            label += label === ReportType.MET ? ' REPORT' : '';
            return (
                <span key={`${lowerLabel}TypeOption`}>
                    <RadioInput
                        label={label}
                        value={value}
                        name="reportType"
                        id={`${lowerLabel}Type`}
                        key={`${lowerLabel}TypeOption`}
                        formRegistering={formRegistering}
                    />
                </span>
            );
        });
    }

    function renderForm() {
        switch (watchReportType) {
            case 'METAR':
                return (
                    <MetarReport
                        config={config}
                        onReportDiscard={onReportDiscard}
                        cor={{ ...ENLARGED_FEATURE_ON, status: false }}
                    />
                );
            case 'MET':
                return (
                    <MetReport
                        config={config}
                        onReportDiscard={onReportDiscard}
                        special={{ ...ENLARGED_FEATURE_ON, status: false }}
                    />
                );
            default:
                return <>Select report type.</>;
        }
    }

    return (
        <div className="flex-full-height">
            <p className="mb-2">Select type of report:</p>
            <span>{renderReportTypeInputs()}</span>
            <div className="mt-9 flex-full-height">{renderForm()}</div>
        </div>
    );
};
