import React, { FC } from 'react';
import { FeatureFlags } from 'consts';

import { useFeatureFlagsContext } from 'tools/contexts/FeatureFlagsContext';

type ProtectFeatureByFlagProps = {
    flag: FeatureFlags;
    children: JSX.Element | JSX.Element[];
};
export const ProtectFeatureByFlag: FC<ProtectFeatureByFlagProps> = ({
    flag,
    children,
}) => {
    const { checkFlag } = useFeatureFlagsContext();

    if (checkFlag(flag)) {
        return <>{children}</>;
    }
    return <></>;
};
