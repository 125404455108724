import classNames from 'classnames';
import SL, { GroupBase } from 'react-select';
import React, { useCallback, useMemo, FC, useState } from 'react';

import { useDarkMode } from 'tools/contexts/DarkModeContext';

import './select.css';
import { DropdownIndicator, Menu } from './common';
import { SelectProps, SelectOption, BaseMenuProps } from './types';
import {
    getColorTheme,
    getSelectStyles,
    matchValueToSelectOption,
} from './helpers';

export const Select: FC<SelectProps> = ({
    options,
    onChange,
    className,
    borders = true,
    disabled = false,
    isSearchable = true,
    value: selectedValue,
}) => {
    const { darkMode } = useDarkMode();
    const [menuPlacement, setMenuPlacement] = useState<string>('');
    const value = useMemo(
        () => matchValueToSelectOption(options, selectedValue),
        [options, selectedValue]
    );
    const selectStyles = useMemo(
        () => getSelectStyles(darkMode, menuPlacement, borders),
        [darkMode, menuPlacement, borders]
    );
    const colourTheme = useMemo(() => getColorTheme(darkMode), [darkMode]);
    const ExtendedMenu = useCallback(
        (menuProps: BaseMenuProps) => (
            <Menu {...menuProps} setMenuPlacement={setMenuPlacement} />
        ),
        []
    );

    const onChangeHandler = useCallback(
        ({ value: updatedValue }) => {
            if (onChange) {
                onChange(updatedValue);
            }
        },
        [onChange]
    );

    return (
        <SL<SelectOption, true, GroupBase<SelectOption>>
            value={value}
            options={options}
            theme={colourTheme}
            menuPlacement="auto"
            isDisabled={disabled}
            styles={selectStyles}
            onChange={onChangeHandler}
            isSearchable={isSearchable}
            classNamePrefix="react-select"
            components={{ DropdownIndicator, Menu: ExtendedMenu }}
            className={classNames(className, `react-select-${menuPlacement}`)}
        />
    );
};
