import { FormSchema } from '../../types';

const metarForm: FormSchema = {
    formFields: [
        {
            title: 'Wind',
            id: 'surface_wind',
            placeHolderText: '07010KT',
            columnsWidth: 2,
            validationFieldName: 'surface_wind',
        },
        {
            title: 'Visibility',
            id: 'visibility',
            placeHolderText: '0500',
            columnsWidth: 2,
            validationFieldName: 'visibility',
            cavokValue: '',
        },
        {
            title: 'RVR',
            id: 'rvr',
            placeHolderText: 'R19/0650',
            columnsWidth: 2,
            validationFieldName: 'rvr',
            cavokValue: '',
        },
        {
            title: 'Cloud',
            id: 'cloud',
            placeHolderText: 'FEW011 BKN062',
            columnsWidth: 2,
            validationFieldName: 'cloud',
            cavokValue: '',
        },
        {
            title: 'Present Weather',
            id: 'present_weather',
            placeHolderText: '-SN BR',
            columnsWidth: 4,
            validationFieldName: 'present_weather',
            cavokValue: '',
        },
        {
            title: 'Temperature/Dewpoint',
            id: 'temperature_and_dew_point',
            placeHolderText: '25/14',
            columnsWidth: 2,
            validationFieldName: 'temperature_and_dew_point',
        },
        {
            title: 'Pressure',
            id: 'pressure',
            placeHolderText: 'Q1004',
            columnsWidth: 2,
            validationFieldName: 'pressure',
        },
        {
            title: 'Recent Weather',
            id: 'recent_weather',
            placeHolderText: 'REFZDZ RESHRA RERA',
            columnsWidth: 4,
            validationFieldName: 'recent_weather',
        },
    ],
};

export default metarForm;
