import React from 'react';
import ReactModal from 'react-modal';
import BasicWindowContainer from './BasicWindowContainer';

type CustomAlertProps = {
    isModalShown: boolean;
    handleClose: () => void;
    children?: React.ReactNode;
};

const styles = {
    button:
        'flex flex-row items-center justify-center h-10 float-right mb-2 rounded bg-awos-navy-2 text-awos-white-4 font-semibold dark:bg-awos-black-4' +
        ' hover:bg-awos-navy-3 dark:hover:bg-awos-black-12' +
        ' active:bg-awos-navy-4 dark:active:bg-awos-gray-21',
};

const CustomAlert: React.FunctionComponent<CustomAlertProps> = ({
    isModalShown,
    handleClose,
    children,
}: CustomAlertProps) => {
    return (
        <ReactModal
            appElement={document.getElementById('root') as HTMLElement}
            role="alertdialog"
            isOpen={isModalShown}
            onRequestClose={handleClose}
            shouldCloseOnOverlayClick={false}
            overlayClassName="z-50 fixed inset-0 bg-awos-black-10 bg-opacity-50 overflow-auto dark:bg-opacity-75"
            className="center mx-auto my-auto mt-36 relative text-awos-navy-2 focus:outline-none dark:text-awos-white-3 w-96"
        >
            <BasicWindowContainer
                styles={{
                    container: 'border',
                    topBar: 'flex px-4 py-1 justify-between bg-awos-white-2 text-awos-navy-2 dark:bg-awos-black-4',
                }}
                topLeft={
                    <span className="inline font-semibold text-awos-navy-2 text-lg dark:text-awos-white-3">
                        <span className="align-middle">Alert</span>
                    </span>
                }
            >
                <div className="px-4 h-20">
                    <div>{children}</div>
                    <button
                        type="button"
                        className={`w-16 ${styles.button}`}
                        onClick={handleClose}
                    >
                        OK
                    </button>
                </div>
            </BasicWindowContainer>
        </ReactModal>
    );
};

CustomAlert.defaultProps = {
    children: <></>,
};

export default CustomAlert;
