import React, { FC } from 'react';

import { Roles } from 'consts';
import { useKeycloak } from 'tools/contexts/KeycloakContext';

type ProtectFeatureByRoleProps = {
    roles: {
        included?: Roles[];
        excluded?: Roles[];
    };
    children: JSX.Element | JSX.Element[];
};
export const ProtectFeatureByRole: FC<ProtectFeatureByRoleProps> = ({
    roles,
    children,
}) => {
    const { checkRoles } = useKeycloak();

    if (checkRoles(roles)) {
        return <>{children}</>;
    }

    return <></>;
};
