import React from 'react';
import classNames from 'classnames';
import { Redirect } from 'react-router';
import { twMerge } from 'tailwind-merge';

import { useKeycloak } from 'tools/contexts/KeycloakContext';

import AwosIcon, { IconNames } from './AwosIcon';

interface BasicWindowContainerProps {
    icon?: IconNames;
    className?: string;
    styles?: {
        icon?: string;
        topBar?: string;
        topLeft?: string;
        topRight?: string;
        topCenter?: string;
        container?: string;
    };
    topLeft?: React.ReactNode;
    topRight?: React.ReactNode;
    children?: React.ReactNode;
    topCenter?: React.ReactNode;
    containerWithInputs?: boolean;
}

const ownStyles = {
    container:
        'basic-window-container bg-white rounded-t border-c-basicWindowContainer-light-border dark:bg-c-basicWindowContainer-dark-bg dark:border-c-basicWindowContainer-dark-border',
    containerWithInputs: 'dark:bg-c-basicWindowContainer-dark-bgWithInputs',
    topBar: `basic-window-container-top-bar rounded-t flex px-4 py-1 justify-between
        bg-c-basicWindowContainer-light-topBar dark:bg-c-basicWindowContainer-dark-topBar
        text-c-basicWindowContainer-light-topText dark:text-c-basicWindowContainer-dark-topText`,
    topLeft:
        'flex h-full items-center text-left text-sm sm:text-base 2xl:text-lg text-c-basicWindowContainer-light-sideText dark:text-c-basicWindowContainer-dark-sideText',
    topCenter:
        'flex h-full items-center text-center text-sm sm:text-base 2xl:text-lg font-semibold text-c-basicWindowContainer-light-topCenter dark:text-c-basicWindowContainer-dark-topCenter',
    topRight:
        'flex h-full items-center text-right text-sm sm:text-base 2xl:text-lg text-c-basicWindowContainer-light-sideText dark:text-c-basicWindowContainer-dark-sideText',
    icon: 'text-white',
};

const BasicWindowContainer: React.FunctionComponent<
    BasicWindowContainerProps
> = ({
    icon,
    topLeft,
    topRight,
    children,
    topCenter,
    styles = {},
    className,
    containerWithInputs = false,
}: BasicWindowContainerProps) => {
    // TODO we really need this check?
    const { keycloak } = useKeycloak();
    if (!keycloak?.authenticated) return <Redirect to="/" />;

    return (
        <div
            className={classNames(
                className,
                ownStyles.container,
                containerWithInputs && ownStyles.containerWithInputs,
                styles.container
            )}
        >
            <div
                className={classNames(twMerge(ownStyles.topBar, styles.topBar))}
            >
                <div
                    className={classNames(
                        twMerge(ownStyles.topLeft, styles.topLeft)
                    )}
                >
                    <AwosIcon
                        iconName={icon}
                        styles={classNames(ownStyles.icon, styles.icon)}
                    />
                    {topLeft}
                </div>
                <div
                    className={classNames(
                        ownStyles.topCenter,
                        styles.topCenter
                    )}
                >
                    {topCenter}
                </div>
                <div
                    className={classNames(ownStyles.topRight, styles.topRight)}
                >
                    {topRight}
                </div>
            </div>
            <>{children}</>
        </div>
    );
};

export default BasicWindowContainer;
