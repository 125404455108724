import React from 'react';
import { Redirect } from 'react-router';

import { useKeycloak } from 'tools/contexts/KeycloakContext';

const Reports: React.FunctionComponent = () => {
    const { keycloak } = useKeycloak();

    if (!keycloak?.authenticated) return <Redirect to="/" />;

    return (
        <div className="flex-col">
            <div className="bg-pink-500">
                <div className="m-auto text-6xl">
                    <h1>Tools</h1>
                </div>
            </div>
        </div>
    );
};

export default Reports;
