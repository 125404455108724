import React, { FC } from 'react';

import AwosIcon from 'pages/generic/AwosIcon';

const styles = {
    icon: `h-5 mr-4`,
    notification: `flex items-center bg-awos-blue-2-30 rounded py-2 px-3 mb-4`,
};

export const ReportNotificationMessage: FC<{ message?: string; }> = ({ message }) => (
    message ? <div className={styles.notification}>
        <AwosIcon
            iconName="warn"
            styles={styles.icon}
        /> 
        {message}
    </div> : null
);
