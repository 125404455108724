import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import React from 'react';

import { keycloak } from 'keycloak';
import { FeatureFlags, Roles } from 'consts';

import { useGetData } from 'tools/hooks/getData';
import { ProtectFeatureByFlag, ProtectFeatureByRole } from 'tools/protectors';

import SVGIcon from 'assets/icons/SVGIcon';
import { ReactComponent as HomeSVG } from 'assets/icons/svg/Home_on.svg';
import { ReactComponent as ToolsSVG } from 'assets/icons/svg/Tools_on.svg';
import { ReactComponent as ReportSVG } from 'assets/icons/svg/Report_on.svg';
import { ReactComponent as LogoutSVG } from 'assets/icons/svg/Logout_off.svg';
import { ReactComponent as SettingsSVG } from 'assets/icons/svg/Settings_on.svg';
import { ReactComponent as DashboardSVG } from 'assets/icons/svg/Dashboard_on.svg';

import './side-menu.css';
import sideMenuStyles from './side-menu.module.css';

type SideMenuProps = {
    showSideMenu: boolean;
};

const SideMenu: React.FunctionComponent<SideMenuProps> = ({
    showSideMenu,
}: SideMenuProps) => {
    const styles = {
        container: 'duration-500 ease-in-out',
        nav: `fixed z-10 top-16 bottom-0 border-t dark:border-awos-gray-15 ${
            showSideMenu ? 'w-56' : 'w-20 sidemenu-closed'
        } py-4 px-2.5 border-r dark:border-awos-gray-15 text-left text-base md:text-lg text-awos-navy-1 overflow-hidden transition-width ease-in-out bg-c-sidemenu-light dark:bg-c-sidemenu-dark`,
        sideMenuLi: 'duration-500 transition-width flex whitespace-nowrap',
        sideMenuIcon: 'inline h-10 w-10 ml-2 p-2.5',
        span: `transition-opacity ease-in-out ${
            showSideMenu ? 'opacity-100' : 'opacity-0'
        }`,
        spanInfo: `transition-opacity ease-in-out ${
            showSideMenu ? 'opacity-100' : 'opacity-0'
        } ml-4 py-1 truncate text-awos-gray-7 text-xs`,
        navLink: `mt-4 py-2 w-full rounded h-10 flex items-center  hover:bg-awos-gray-2 hover:text-awos-navy-1 dark:text-awos-white-3 dark:hover:text-awos-gray-15 dark:hover:bg-awos-white-3`,
        activeNavLink:
            'pointer-events-none bg-awos-navy-1 text-white dark:bg-awos-white-3 dark:text-awos-gray-15',
    };

    const { data: version } = useGetData({
        enabled: true,
        retry: true,
        endpoint: '/version',
        initialValue: 'version undefined',
        queryName: 'version',
    });
    return (
        <div className={styles.container}>
            <nav className={classNames(styles.nav, sideMenuStyles.navigation)}>
                <ul
                    data-testid="side-menu-nav-ul"
                    className="m-0 p-0 flex flex-col h-full"
                >
                    <li className={styles.sideMenuLi}>
                        <NavLink
                            to="/home"
                            className={styles.navLink}
                            activeClassName={styles.activeNavLink}
                        >
                            <SVGIcon
                                lightIcon={
                                    <HomeSVG
                                        className={styles.sideMenuIcon}
                                        aria-hidden="true"
                                        focusable={false}
                                        role="img"
                                    />
                                }
                            />
                            <span className={styles.span}>Home</span>
                        </NavLink>
                    </li>

                    <ProtectFeatureByFlag flag={FeatureFlags.DASHBOARDS}>
                        <li className={styles.sideMenuLi}>
                            <NavLink
                                data-testid="dashboards-button"
                                to="/dashboards"
                                className={styles.navLink}
                                activeClassName={styles.activeNavLink}
                            >
                                <SVGIcon
                                    lightIcon={
                                        <DashboardSVG
                                            className={styles.sideMenuIcon}
                                            aria-hidden="true"
                                            focusable={false}
                                            role="img"
                                        />
                                    }
                                />
                                <span className={styles.span}>Dashboards</span>
                            </NavLink>
                        </li>
                    </ProtectFeatureByFlag>

                    <ProtectFeatureByFlag flag={FeatureFlags.REPORTS}>
                        <li className={styles.sideMenuLi}>
                            <NavLink
                                to="/reports"
                                className={styles.navLink}
                                activeClassName={styles.activeNavLink}
                            >
                                <SVGIcon
                                    lightIcon={
                                        <ReportSVG
                                            className={styles.sideMenuIcon}
                                            aria-hidden="true"
                                            focusable={false}
                                            role="img"
                                        />
                                    }
                                />

                                <span className={styles.span}>Reports</span>
                            </NavLink>
                        </li>
                    </ProtectFeatureByFlag>

                    <ProtectFeatureByFlag flag={FeatureFlags.TOOLS}>
                        <li className={styles.sideMenuLi}>
                            <NavLink
                                to="/tools"
                                className={styles.navLink}
                                activeClassName={styles.activeNavLink}
                            >
                                <SVGIcon
                                    lightIcon={
                                        <ToolsSVG
                                            className={styles.sideMenuIcon}
                                            aria-hidden="true"
                                            focusable={false}
                                            role="img"
                                        />
                                    }
                                />

                                <span className={styles.span}>Tools</span>
                            </NavLink>
                        </li>
                    </ProtectFeatureByFlag>

                    <ProtectFeatureByRole
                        roles={{ included: [Roles.ACCESS_SETTINGS] }}
                    >
                        <li className={styles.sideMenuLi}>
                            <NavLink
                                to="/settings"
                                className={styles.navLink}
                                activeClassName={styles.activeNavLink}
                            >
                                <SVGIcon
                                    lightIcon={
                                        <SettingsSVG
                                            className={styles.sideMenuIcon}
                                            aria-hidden="true"
                                            focusable={false}
                                            role="img"
                                        />
                                    }
                                />

                                <span className={styles.span}>Settings</span>
                            </NavLink>
                        </li>
                    </ProtectFeatureByRole>

                    <div className="mt-auto">
                        <li className="side-menu-li">
                            <div
                                className={`${styles.spanInfo} ml-0 text-left`}
                            >
                                Software Version: {version}
                            </div>
                        </li>
                        <li
                            className={`${styles.sideMenuLi} border-t dark:border-awos-gray-15`}
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    keycloak?.logout({
                                        redirectUri: keycloak.createLoginUrl(),
                                    });
                                }}
                                className="text-left my-2.5 w-full rounded hover:bg-awos-gray-2 active:bg-awos-navy-1 active:text-white dark:text-awos-white-3 dark:hover:text-awos-gray-15 dark:hover:bg-awos-white-3"
                            >
                                <SVGIcon
                                    lightIcon={
                                        <LogoutSVG
                                            className={styles.sideMenuIcon}
                                            aria-hidden="true"
                                            focusable={false}
                                            role="img"
                                        />
                                    }
                                />

                                <span className={styles.span}>Log Out</span>
                            </button>
                        </li>
                    </div>
                </ul>
            </nav>
        </div>
    );
};

export { SideMenu };
