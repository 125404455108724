import React, { FC } from 'react';

const styles = `font-semibold outline-none bg-transparent`;
const placeholder = <span className='opacity-0'>PLACEHOLDER</span>;

export const AirportCode: FC<{ codeId: string; }> = ({ codeId = '' }) => (
    <div className="pr-3">
        <label htmlFor="icao">
            Aerodrome Location
            <p className={styles}>
                {codeId.toUpperCase() || placeholder}
            </p>
            <input
                readOnly
                hidden
                id={codeId}
                value={codeId.toUpperCase()}
            />
        </label>
    </div>
);