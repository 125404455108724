import { get } from 'lodash';
import React, { ReactElement } from 'react';

import Table from 'pages/generic/Table';
import Windrose, { WindroseData } from 'pages/generic/Windrose';
import { SyncFailedSpinner } from 'pages/generic/syncFailedSpinner';
import BasicWindowContainer from 'pages/generic/BasicWindowContainer';

import { DEAFAULT_WINDROSE_DATA } from '../consts';

type SidePanelProps = {
    windrose?: {
        data: WindroseData;
        isSyncFailed: boolean;
    };
    className?: string;
};

export const SidePanel = ({
    windrose,
    className = '',
}: SidePanelProps): ReactElement => {
    const { data = DEAFAULT_WINDROSE_DATA, isSyncFailed = false } =
        windrose || {};
    const mnm = get(data, 'velocity.min');
    const max = get(data, 'velocity.max');

    return (
        <div className="relative">
            <SyncFailedSpinner isSyncFailed={isSyncFailed} />
            <div className={className}>
                <BasicWindowContainer
                    styles={{
                        container: 'border',
                        topLeft:
                            'text-awos-blue-3 dark:text-c-basicWindowContainer-dark-sideText',
                        topRight:
                            'text-awos-blue-3 dark:text-c-basicWindowContainer-dark-sideText',
                    }}
                    topLeft={mnm ? <span>MNM {mnm || '////'}</span> : null}
                    topCenter={
                        <span
                            className={data.runway_active ? '' : 'text-white'}
                        >
                            RUNWAY{' '}
                            {data.runway_direction
                                ? Math.floor(data.runway_direction / 10)
                                      .toString()
                                      .padStart(2, '0')
                                : '////'}
                        </span>
                    }
                    topRight={max ? <span>MAX {max || '////'}</span> : null}
                >
                    <Windrose data={data} runway_active={data.runway_active} />
                    <Table data={data.table} containerStyles="p-5" />
                </BasicWindowContainer>
            </div>
        </div>
    );
};
