import {
    withDefault,
    NumberParam,
    BooleanParam,
    useQueryParam,
} from 'use-query-params';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';

import LoadingSpinner from 'pages/generic/LoadingSpinner';
import { QuerySelect } from 'pages/generic/form/QuerySelect';
import AwosIcon, { IconNames } from 'pages/generic/AwosIcon';

import ReportRecord from './ReportRecord';
import { ReportsTableData } from './ReportTypes';

type ReportTableProps = {
    reportsTableData: ReportsTableData;
};

type PageButtonProps = {
    iconName: IconNames;
    refPageNumber: number | undefined;
};

const styles = {
    dateCell: 'h-10 w-44 text-left sm:w-48 md:w-52 lg:w-56',
    nameCell: 'h-10 text-left font-medium w-full',
    headerRow:
        ' bg-awos-white-2 border-b border-awos-grayscale-38 dark:bg-awos-black-3',
    pageButton: `
        border disabled:cursor-not-allowed
        ml-1 md:ml-2 lg:ml-3  flex flex-wrap justify-center content-center w-10 h-10 rounded
        border-c-button-ligth-scBorder dark:border-c-button-dark-scBorder
        text-c-button-light-scText bg-c-button-light-scBackground
        hover:bg-c-button-light-scHoverBackground active:bg-c-button-light-scActiveBackground
        disabled:bg-c-button-light-scDisabledBackground disabled:text-c-button-light-scDisabledText
        dark:text-c-button-dark-scText dark:bg-c-button-dark-scBackground
        dark:hover:bg-c-button-dark-scHoverBackground dark:active:bg-c-button-dark-scActiveBackground
        dark:disabled:bg-c-button-dark-scDisabledBackground dark:disabled:text-c-button-dark-scDisabledText 
        `,
};

const tableSizeOptions = [
    {
        value: '15',
        label: '15/Page',
    },
    {
        value: '60',
        label: '60/Page',
    },
    {
        value: '120',
        label: '120/Page',
    },
];

const ReportTable: React.FunctionComponent<ReportTableProps> = ({
    reportsTableData,
}: ReportTableProps) => {
    const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0));

    const [descendingSortByDate, setDescendingSortByDate] =
        useState<boolean>(true);
    const [, setDescendingSortByDateQuery] = useQueryParam(
        'desc_sorting',
        withDefault(BooleanParam, true)
    );

    useEffect(() => {
        setDescendingSortByDateQuery(descendingSortByDate);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const currentPage = reportsTableData?.paging?.current;
        if (!isNil(currentPage)) setPage(currentPage);
    }, [reportsTableData, setPage]);

    const PageButton = ({
        iconName,
        refPageNumber,
    }: PageButtonProps): JSX.Element => {
        return (
            <button
                id="page-buttons"
                type="button"
                className={styles.pageButton}
                onClick={() => setPage(refPageNumber)}
                disabled={refPageNumber === undefined}
            >
                <AwosIcon
                    styles={`h-3 ${
                        refPageNumber === undefined ? 'text-awos-gray-12' : ''
                    }`}
                    iconName={iconName}
                />
            </button>
        );
    };

    const checkNull = (value: number | undefined | null) => {
        if (value === null) return undefined;
        return value;
    };

    const reportsListInfoString = (): string => {
        const { paging } = reportsTableData;

        if (isNil(paging)) return '0 of 0';

        const perPage: number = paging?.per_page ?? 0;
        const currentPage: number = paging?.current ?? page ?? 0;
        const total: number = paging?.total_reports ?? 0;

        const from: number = perPage * currentPage;
        const to: number = perPage * (currentPage + 1);

        return `${from + 1}-${to > total ? total : to} of ${total}`;
    };

    const generateHead = (): JSX.Element => {
        return (
            <tr className={styles.headerRow}>
                <th className={styles.dateCell}>
                    <button
                        type="button"
                        onClick={() => {
                            setDescendingSortByDateQuery(!descendingSortByDate);
                            setDescendingSortByDate(!descendingSortByDate);
                        }}
                    >
                        <span className="pl-2 font-medium inline-block mr-1">
                            Date
                        </span>
                        <div className="inline-block -mb-px">
                            <AwosIcon
                                iconName="arrow-up"
                                styles={` duration-500 w-2.5 ${
                                    !descendingSortByDate
                                        ? 'dark:text-awos-white-3'
                                        : 'text-awos-gray-10 dark:text-awos-gray-19'
                                }`}
                            />
                            <AwosIcon
                                iconName="arrow-down"
                                styles={` duration-500 w-2.5 mt-1 ${
                                    !descendingSortByDate
                                        ? 'text-awos-gray-10 dark:text-awos-gray-19'
                                        : 'dark:text-awos-white-3'
                                }`}
                            />
                        </div>
                    </button>
                </th>
                <th className={styles.nameCell}>
                    <span className="pl-2 font-medium border-l border-awos-grayscale-38">
                        Report
                    </span>
                </th>
            </tr>
        );
    };

    const generateBody = (): JSX.Element | JSX.Element[] => {
        const recordsData = reportsTableData?.reports;

        if (isNil(recordsData)) {
            return (
                <tr key={-1}>
                    <td className="h-20" colSpan={2}>
                        <div className="flex justify-center">
                            <LoadingSpinner />
                        </div>
                    </td>
                </tr>
            );
        }

        if (isEmpty(recordsData)) {
            return (
                <tr key={-1}>
                    <td className="h-20 text-center" colSpan={2}>
                        No reports to display.
                    </td>
                </tr>
            );
        }

        return recordsData.map((value) => (
            <ReportRecord
                key={`${value.data}-${value.timestamp}`}
                data={value}
            />
        ));
    };

    const generateFoot = (): JSX.Element => {
        return (
            <tr>
                <td colSpan={3}>
                    <div className="py-5 grid grid-cols-1 justify-items-stretch">
                        <div className="flex flex flex-wrap text-center content-center justify-end px-1 md:px-2 lg:px-3">
                            <QuerySelect
                                className="w-24 sm:w-28 z-20 text-sm sm:text-base w-24"
                                options={tableSizeOptions}
                                queryName="per_page"
                            />
                            <p className="flex flex-wrap content-center text-sm sm:text-base pl-2 md:pl-4 lg:pl-5">
                                {reportsListInfoString()}
                            </p>
                            <PageButton
                                iconName="first"
                                refPageNumber={checkNull(
                                    reportsTableData.paging?.first
                                )}
                            />
                            <PageButton
                                iconName="chevron-left"
                                refPageNumber={checkNull(
                                    reportsTableData.paging?.previous
                                )}
                            />
                            <PageButton
                                iconName="chevron-right"
                                refPageNumber={checkNull(
                                    reportsTableData.paging?.next
                                )}
                            />
                            <PageButton
                                iconName="last"
                                refPageNumber={checkNull(
                                    reportsTableData.paging?.last
                                )}
                            />
                        </div>
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <div>
            <table className="w-full table-fixed text-awos-navy-2 dark:text-awos-white-3">
                <thead>{generateHead()}</thead>
                <tbody>{generateBody()}</tbody>
                <tfoot>{generateFoot()}</tfoot>
            </table>
        </div>
    );
};

export default ReportTable;
