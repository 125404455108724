import { get } from 'lodash';
import { ReactElement } from 'react';

import { ReactComponent as RunwayIcon } from 'assets/icons/svg/Airport runway.svg';
import { ReactComponent as WindArrowIcon } from 'assets/icons/svg/Wind direction.svg';
import { ReactComponent as AirplaneIcon } from 'assets/icons/svg/Airplane_header.svg';

import { TableData } from './Table';

type Range = {
    min: number;
    max: number;
};

type WindData = {
    current?: number;
    mean2?: Range;
    mean10: Range;
};

export type WindroseData = {
    runway_name?: string;
    runway_direction?: number;
    runway_active: boolean;
    velocity?: Range;
    directions?: WindData;
    current?: string;
    table?: TableData;
};

type WindroseProps = {
    data?: WindroseData;
    runway_active: boolean;
};

const defaultWindroseProps: WindroseData = {
    runway_name: 'Wind',
    runway_direction: undefined,
    runway_active: false,
    velocity: undefined,
    directions: undefined,
    current: undefined,
    table: undefined,
};

const getRadiuses = (withMean2: boolean) => ({
    mean2: 0.59,
    mean10: 0.72,
    majorTick: 0.84,
    minorTick: 0.82,
    majorNumbers: 0.91,
    overflowCircle: 0.8,
    windArrow: withMean2 ? 0.46 : 0.59,
});

const Windrose = ({ data, runway_active }: WindroseProps): ReactElement => {
    const MEAN_OFFSET = 5;

    const args = data !== undefined ? data : defaultWindroseProps;

    const p2c = (r: number, a: number) => {
        return {
            x: r * Math.cos((a * Math.PI) / 180 - Math.PI / 2),
            y: r * Math.sin((a * Math.PI) / 180 - Math.PI / 2),
        };
    };
    const withMean2 = Boolean(get(args, 'directions.mean2'));
    const RADIUSES = getRadiuses(withMean2);

    const styles = {
        majorTick:
            'stroke-current text-c-windrose-light-majorTick dark:text-c-windrose-dark-majorTick',
        minorTick:
            'stroke-current text-c-windrose-light-minorTick dark:text-c-windrose-dark-minorTick',
        majorNumbers:
            'fill-current font-semibold text-c-windrose-light-majorNumbers dark:text-c-windrose-dark-majorNumbers',
        archBox: 'fill-current stroke-current',
        runway: 'fill-current',
        airplane:
            'fill-current text-c-windrose-light-airplane dark:text-c-windrose-dark-airplane',
        windArrow:
            'fill-current text-c-windrose-light-windArrow dark:text-c-windrose-dark-windArrow',
        circle: 'fill-current text-c-windrose-light-circle dark:text-c-windrose-dark-circle',
        mean2: {
            color: 'text-c-windrose-light-mean2-color',
            darkColor: 'dark:text-c-windrose-dark-mean2-color',
            active: 'text-c-windrose-light-mean2-activeColor',
            darkActive: 'dark:text-c-windrose-dark-mean2-activeColor',
        },
        mean10: {
            color: 'text-c-windrose-light-mean10-color',
            darkColor: 'dark:text-c-windrose-dark-mean10-color',
            active: 'text-c-windrose-light-mean10-activeColor',
            darkActive: 'dark:text-c-windrose-dark-mean10-activeColor',
        },
        instantWindBox:
            'absolute top-0 left-0 w-full h-full flex justify-center items-center',
        instantWindText: `
            text-c-windrose-light-instantText  dark:text-c-windrose-dark-instantText 
            bg-c-windrose-light-instantBg dark:bg-c-windrose-dark-instantBg
            flex justify-center items-center p-2 rounded font-semibold text-md 
        `,
    };

    const MajorTick = () => {
        let ticks: JSX.Element[] = [];

        const r = RADIUSES.majorTick;

        for (let i = 0; i < 180; i += 30) {
            ticks = ticks.concat(
                <line
                    key={`major-tick-${i}`}
                    x1="0"
                    y1={-r}
                    x2="0"
                    y2={r}
                    className={styles.majorTick}
                    strokeWidth="0.014"
                    transform={`rotate(${i})`}
                />
            );
        }

        return <>{ticks}</>;
    };

    const MinorTick = () => {
        let ticks: JSX.Element[] = [];

        const r = RADIUSES.minorTick;

        for (let i = 0; i < 180; i += 10) {
            ticks = ticks.concat(
                <line
                    key={`minor-tick-${i}`}
                    x1="0"
                    y1={-r}
                    x2="0"
                    y2={r}
                    className={styles.minorTick}
                    strokeWidth="0.014"
                    transform={`rotate(${i})`}
                />
            );
        }

        return <>{ticks}</>;
    };

    const MajorNumbers = () => {
        let numbers: JSX.Element[] = [];

        const r = RADIUSES.majorNumbers;

        for (let i = 30; i <= 360; i += 30) {
            const p = p2c(r, i);

            numbers = numbers.concat(
                <text
                    key={`major-numbers-${i}`}
                    x="0"
                    y="0"
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    fontSize="0.06"
                    transform={`translate(${p.x} ${p.y})`}
                    className={styles.majorNumbers}
                >
                    {i}
                </text>
            );
        }

        return <>{numbers}</>;
    };

    const ArchBox = ({
        offset,
        radius,
        color,
    }: {
        offset: number;
        radius: number;
        color: string;
    }) => {
        const r = radius;
        const startAngle = 5 + offset - 1;
        const endAngle = -5 + offset + 1;
        const start = p2c(r, startAngle);
        const end = p2c(r, endAngle);
        return (
            <path
                className={`${styles.archBox} ${color}`}
                strokeWidth="0.11"
                d={`M ${start.x} ${start.y} A ${r} ${r} 0 0 0 ${end.x} ${end.y}`}
            />
        );
    };

    const Mean = ({
        radius,
        color,
        darkColor,
        activRange,
        activeColor,
        activeColorDark,
    }: {
        radius: number;
        color: string;
        darkColor: string;
        activRange: Range | undefined;
        activeColor: string;
        activeColorDark: string;
    }) => {
        let archBoxes: JSX.Element[] = [];

        let rangeMin: number;
        let rangeMax: number;
        let reverse: boolean;

        const checkRange = (archAngle: number): boolean => {
            if (reverse) {
                return (
                    archAngle >= rangeMin + MEAN_OFFSET &&
                    archAngle <= rangeMax - MEAN_OFFSET
                );
            }
            return (
                archAngle >= rangeMin - MEAN_OFFSET &&
                archAngle <= rangeMax + MEAN_OFFSET
            );
        };

        const getArchBox = (active: boolean, offset: number): JSX.Element => {
            if (active) {
                return (
                    <ArchBox
                        key={`archbox-${offset}`}
                        offset={offset}
                        radius={radius}
                        color={`${activeColor} ${activeColorDark}`}
                    />
                );
            }
            return (
                <ArchBox
                    key={`archbox-${offset}`}
                    offset={offset}
                    radius={radius}
                    color={`${color} ${darkColor}`}
                />
            );
        };

        if (activRange) {
            if (activRange.min > activRange.max) {
                rangeMax = activRange.min;
                rangeMin = activRange.max;
                reverse = true;
            } else {
                rangeMax = activRange.max;
                rangeMin = activRange.min;
                reverse = false;
            }

            for (let i = 0; i < 360; i += 10) {
                if (checkRange(i)) {
                    archBoxes = archBoxes.concat(getArchBox(!reverse, i));
                } else {
                    archBoxes = archBoxes.concat(getArchBox(reverse, i));
                }
            }
        } else {
            for (let i = 0; i < 360; i += 10) {
                archBoxes = archBoxes.concat(getArchBox(false, i));
            }
        }

        return <>{archBoxes}</>;
    };

    const Runway = ({
        direction,
        iconWidth,
    }: {
        direction: number;
        iconWidth: number;
    }) => {
        // when mean2 is present we render 2 circles, and icon should be smaller
        // when mean2 is not present we need to enlarge icon
        const multiplier = withMean2 ? 1 : 1.4;
        return (
            <g transform={`rotate(${direction})`} className={styles.runway}>
                <RunwayIcon
                    x={-0.5 * iconWidth * multiplier}
                    y={-0.5 * iconWidth * multiplier}
                    width={iconWidth * multiplier}
                    height={iconWidth * multiplier}
                />
            </g>
        );
    };

    const Airplane = ({
        direction,
        iconWidth,
    }: {
        direction: number;
        iconWidth: number;
    }) => {
        // when mean2 is present we render 2 circles, and icon should be smaller
        // when mean2 is not present we need to enlarge icon
        const multiplier = withMean2 ? 1 : 1.4;
        return (
            <g
                transform={`rotate(${direction})
                            translate(0 ${1.2 * iconWidth})`}
                className={styles.airplane}
            >
                <AirplaneIcon
                    x={-0.5 * iconWidth * multiplier}
                    y={-0.5 * iconWidth * multiplier}
                    width={iconWidth * multiplier}
                    height={iconWidth * multiplier}
                />
            </g>
        );
    };

    const WindArrow = ({ a, iconWidth }: { a: number; iconWidth: number }) => {
        const p = p2c(RADIUSES.windArrow, a);
        return (
            <g
                transform={`
                    translate(${p.x} ${p.y})
                    rotate(${a + 180})`}
                className={styles.windArrow}
            >
                <WindArrowIcon
                    x={-0.5 * iconWidth}
                    y={-0.5 * iconWidth}
                    width={iconWidth}
                    height={iconWidth}
                />
            </g>
        );
    };

    const InstantWind = () => {
        return (
            <div className={styles.instantWindBox}>
                <div className={styles.instantWindText}>
                    {data?.current ?? ''}
                </div>
            </div>
        );
    };

    return (
        <div className="w-full py-2 relative">
            <svg viewBox="-1 -1 2 2">
                <MinorTick />
                <MajorTick />
                <circle
                    className={styles.circle}
                    cx="0"
                    cy="0"
                    r={RADIUSES.overflowCircle}
                />
                <MajorNumbers />

                <Runway
                    direction={args.runway_direction ?? 0}
                    iconWidth={0.7}
                />

                {runway_active && args.runway_direction && (
                    <Airplane
                        direction={args.runway_direction}
                        iconWidth={0.2}
                    />
                )}

                {args.directions && args.directions.current ? (
                    <WindArrow a={args.directions.current} iconWidth={0.1} />
                ) : null}

                {withMean2 ? (
                    <Mean
                        radius={RADIUSES.mean2}
                        color={styles.mean2.color}
                        darkColor={styles.mean2.darkColor}
                        activRange={
                            args.directions ? args.directions.mean2 : undefined
                        }
                        activeColor={styles.mean2.active}
                        activeColorDark={styles.mean2.darkActive}
                    />
                ) : null}
                <Mean
                    radius={RADIUSES.mean10}
                    color={styles.mean10.color}
                    darkColor={styles.mean10.darkColor}
                    activRange={
                        args.directions ? args.directions.mean10 : undefined
                    }
                    activeColor={styles.mean10.active}
                    activeColorDark={styles.mean10.darkActive}
                />
            </svg>
            {args.current && <InstantWind />}
        </div>
    );
};

Windrose.defaultProps = { data: defaultWindroseProps };

export default Windrose;
