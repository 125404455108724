import React, { createContext, useContext } from 'react';

import { FeatureFlags as FeatureFlagsEnum } from 'consts';
import { useGetData } from 'tools/hooks/getData';

export type FeatureFlags = Record<FeatureFlagsEnum, boolean>;

export type FeatureFlagsContextProps = {
    isLoading: boolean;
    checkFlag: (flag: FeatureFlagsEnum, exact?: boolean) => boolean | undefined;
    fetchFlags: () => void;
};

const defaultContext: FeatureFlagsContextProps = {
    isLoading: false,
    checkFlag: () => false,
    fetchFlags: () => {},
};

const FeatureFlagsContext =
    createContext<FeatureFlagsContextProps>(defaultContext);

type FeatureFlagsContextProviderProps = {
    children: JSX.Element | JSX.Element[];
    initValue?: FeatureFlags | undefined;
};

export const FeatureFlagsContextProvider = ({
    children,
    initValue,
}: FeatureFlagsContextProviderProps): JSX.Element => {
    const { data: flags, refetch: fetchFlags } = useGetData<
        FeatureFlags | undefined
    >({
        retry: true,
        enabled: true,
        initialValue: initValue,
        endpoint: '/feature-flags',
        queryName: 'featureFlags',
    });

    const isLoading = flags === undefined;

    const checkFlag = (
        flag: FeatureFlagsEnum,
        exect = false
    ): boolean | undefined => {
        if (flags === undefined) {
            return exect ? undefined : false;
        }

        return flags[flag] ?? false;
    };

    return (
        <FeatureFlagsContext.Provider
            value={{ isLoading, checkFlag, fetchFlags }}
        >
            {children}
        </FeatureFlagsContext.Provider>
    );
};

FeatureFlagsContextProvider.defaultProps = {
    initValue: undefined,
};

export const useFeatureFlagsContext = (): FeatureFlagsContextProps =>
    useContext(FeatureFlagsContext);
