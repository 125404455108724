import React from 'react';
import classNames from 'classnames';

type LoadingSpinnerProps = {
    text?: string;
    withShadow?: boolean;
    className?: string;
};

const styles = {
    base: 'flex items-center text-lg text-c-table-light-text dark:text-c-table-dark-text flex-col justify-center align-center',
    shadowed: `absolute inset-0 z-10`,
    shadow: `absolute inset-0 z-9 opacity-50 bg-awos-white-3 dark:bg-awos-black-8`,
};

const LoadingSpinner = ({
    text,
    withShadow,
    className,
}: LoadingSpinnerProps): JSX.Element => {
    return (
        <div
            className={classNames(
                className,
                styles.base,
                withShadow && styles.shadowed
            )}
        >
            {withShadow ? <div className={classNames(styles.shadow)} /> : null}
            <svg
                className="animate-spin h-5 w-5 relative z-15"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                />
                <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
            </svg>
            <div className="relative z-10">{text}</div>
        </div>
    );
};

LoadingSpinner.defaultProps = {
    text: '',
};

export default LoadingSpinner;
