import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { FieldErrors, UseFormRegisterReturn } from 'react-hook-form';
import { FormField } from '../types';

export type Highlight = {
    start: number;
    end: number;
};

type InputHighlightProps = {
    watchSelf: string;
    cavok: boolean;
    errors: FieldErrors;
    highighted: boolean;
    inputField: FormField;
    forceUpperCase?: boolean;
    reg: UseFormRegisterReturn;
    clearErrorsSelf: () => void;
};

export const InputHighlight: React.FunctionComponent<InputHighlightProps> = ({
    reg,
    cavok,
    errors,
    watchSelf,
    inputField,
    clearErrorsSelf,
    highighted = false,
    forceUpperCase = false,
}: InputHighlightProps) => {
    const styles = {
        errorText: 'text-awos-red-2',
        inputTextCommon: 'px-3 pt-2.5 pb-2 text-sm',
        readOnlyInput: `
            dark:text-c-input-dark-textDisabled dark:border-awos-black-3 focus:outline-none
            border rounded border-awos-gray-1 bg-awos-gray-5 text-awos-gray-12 dark:bg-c-input-dark-backgroundDisabled`,
        input: `
            w-full font-normal outline-none border rounded border-c-input-light-border focus:ring ring-awos-blue-3-38 focus:border-c-input-light-borderFocus
            text-c-input-light-text placeholder-awos dark:placeholder-awosDark placeholder-font-normal dark:text-c-input-dark-text
            dark:bg-c-input-dark-background dark:bg-c-input-dark-background dark:ring-awos-gray-18 dark:border-c-input-dark-border
            dark:hover:border-c-input-dark-borderHover hover:border-c-input-light-borderHover
            dark:focus:border-c-input-dark-borderFocus autofill:shadow-amber-200`,
        OverlayDiv:
            'absolute top-0 left-0 pointer-events-none overflow-hidden w-full m-px',
        inputErrorBorder: 'border-awos-red-2 dark:border-awos-red-2',
        label: 'text-sm pb-0.5',
        errorMessage: 'text-awos-red-2 text-xs pl-3 break-words',
        highighted: 'border-awos-blue-2-60 dark:border-awos-blue-2-60 border-2',
    };

    const [showOverlay, setShowOverlay] = useState(false);
    const overlayDiv = useRef<HTMLDivElement>(null);

    const errorRegName = errors[reg.name];
    const errorRegNameMessage = errorRegName && errorRegName.message;

    const highlights =
        errorRegName && errorRegNameMessage
            ? JSON.parse(errorRegNameMessage as string).highlights
            : [];

    if (highlights && highlights.length > 0 && !showOverlay) {
        setShowOverlay(true);
    }

    const highlightInput = (input: string, highlightArray: Highlight[]) => {
        if (!highlights || highlights.length === 0) {
            return <>{input}</>;
        }

        return highlightArray.map((value, index, arr) => {
            return (
                <span key={`${value.start} ${value.end}`}>
                    {index === 0
                        ? input.substring(0, value.start)
                        : input.substring(arr[index - 1].end, value.start)}
                    <span className={styles.errorText}>
                        {input.substring(value.end, value.start)}
                    </span>
                    {index === arr.length - 1
                        ? input.substring(value.end)
                        : null}
                </span>
            );
        });
    };

    const scrollOverlayDivWithInput = (e: React.SyntheticEvent) => {
        if (overlayDiv.current) {
            overlayDiv.current.scrollLeft = (
                e.target as HTMLInputElement
            ).scrollLeft;
        }
    };

    const moveAndHideHighlight = (e: React.SyntheticEvent) => {
        scrollOverlayDivWithInput(e);

        if (highlights && highlights.length !== 0) {
            clearErrorsSelf();
            setShowOverlay(false);
        }
    };

    const handleFocus = () => {
        if (window.getSelection()?.toString() !== '') {
            if (highlights && highlights.length !== 0) {
                clearErrorsSelf();
                setShowOverlay(false);
            }
        }
    };

    const selectProperInput = (cavokState: boolean) => {
        if (cavokState && inputField.cavokValue !== undefined) {
            return (
                <>
                    <input
                        type="text"
                        readOnly
                        tabIndex={-1}
                        id={inputField.id}
                        className={`${styles.readOnlyInput} ${styles.inputTextCommon}`}
                        ref={reg.ref}
                        name={reg.name}
                        onChange={reg.onChange}
                        onBlur={reg.onBlur}
                    />
                </>
            );
        }

        const inputToUpperCase = (e: React.KeyboardEvent<HTMLInputElement>) => {
            const start = e.currentTarget.selectionStart;
            const end = e.currentTarget.selectionEnd;
            e.currentTarget.value = e.currentTarget.value.toUpperCase();
            e.currentTarget.setSelectionRange(start, end);
        };

        return (
            <div className="relative">
                <div
                    className={` ${styles.OverlayDiv} ${
                        styles.inputTextCommon
                    } ${showOverlay ? '' : 'hidden'}`}
                    ref={overlayDiv}
                >
                    {highlightInput(watchSelf, highlights)}
                </div>
                <input
                    type="text"
                    id={inputField.id}
                    className={classNames(
                        styles.inputTextCommon,
                        styles.input,
                        highighted && styles.highighted,
                        errorRegName && styles.inputErrorBorder,
                        { 'text-transparent': showOverlay }
                    )}
                    ref={reg.ref}
                    name={reg.name}
                    onChange={reg.onChange}
                    onBlur={reg.onBlur}
                    onSelect={scrollOverlayDivWithInput}
                    onMouseDown={moveAndHideHighlight}
                    onKeyDown={moveAndHideHighlight}
                    onFocus={handleFocus}
                    spellCheck={false}
                    onKeyUp={forceUpperCase ? inputToUpperCase : undefined}
                />
            </div>
        );
    };

    return (
        <>
            <label htmlFor={inputField.id} className={styles.label}>
                {inputField.title}
            </label>
            {selectProperInput(cavok)}
            {errorRegName && (
                <div className={styles.errorMessage} role="alert">
                    {JSON.parse(errorRegName.message as string).message}
                </div>
            )}
        </>
    );
};
