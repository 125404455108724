import { FC } from 'react';
import classNames from 'classnames';
import { compact, get } from 'lodash';

import { ReportListData } from '../reportFooter';

export const EMPTY_NODE_TEXT = 'No report available';
export const STRING_CLASSNAME = 'telegram-string';
export const EMPTY_STRING_CLASSNAME = `${STRING_CLASSNAME}-empty`;

const telegramStringStyles = (isEmpty: boolean) =>
    compact([
        'border',
        'p-5 min-h-32',
        STRING_CLASSNAME,
        isEmpty && EMPTY_STRING_CLASSNAME,
        'text-awos-navy-2  dark:text-gray-300 text-left',
    ]).join(' ');

export const TelegramString: FC<{ data?: ReportListData }> = ({ data }) => {
    const telegramString = get(data, ['reports', 0, 'data'], EMPTY_NODE_TEXT);
    return (
        <div
            className={classNames(
                telegramStringStyles(telegramString === EMPTY_NODE_TEXT)
            )}
        >
            {telegramString}
        </div>
    );
};
