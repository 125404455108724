import { CSSMotionProps, MotionEventHandler } from 'rc-motion';

const getCollapsedHeight: MotionEventHandler = () => ({ height: 0 });
const getRealHeight: MotionEventHandler = (node) => ({ height: node.scrollHeight });
const getCurrentHeight: MotionEventHandler = (node) => ({ height: node.offsetHeight });

export const collapseMotion: CSSMotionProps = {
    motionDeadline: 500,
    onEnterActive: getRealHeight,
    onLeaveStart: getCurrentHeight,
    onEnterStart: getCollapsedHeight,
    motionName: 'rc-collapse-motion',
    onLeaveActive: getCollapsedHeight,
};