import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { isNil, map, get } from 'lodash';

import { Tooltip } from 'pages/generic/tooltip';
import LoadingSpinner from 'pages/generic/LoadingSpinner';
import AwosIcon, { IconNames } from 'pages/generic/AwosIcon';
import { SyncFailedSpinner } from 'pages/generic/syncFailedSpinner';

export type TableData = {
    name?: string;
    icon?: IconNames;
    columns?: Column[];
    rows?: Row[];
};

export type Column = {
    id: string;
    label: string;
};

export type Row = {
    name?: string;
    cells: Cell[];
};

export type Cell = {
    name?: string;
    column_id?: string;
    value: string;
    emphasize?: boolean;
    conversions?: Conversions[];
};

export type Conversions = {
    value: string;
    unit: string;
};

type TableProps = {
    data?: TableData;
    containerStyles?: string;
    tableStyles?: string;
    className?: string;
    isSyncFailed?: boolean;
};

const Table = ({
    data,
    className,
    tableStyles,
    containerStyles,
    isSyncFailed = false,
}: TableProps): React.ReactElement => {
    const styles = {
        loadingView:
            'flex justify-center align-middle h-20 bg-white dark:bg-c-table-dark-cell border rounded dark:border-c-table-dark-border',
        topBar: 'px-5 text-white text-left rounded-t bg-c-basicWindowContainer-light-topBar dark:bg-c-basicWindowContainer-dark-topBar',
        container: `${containerStyles} block w-full relative`,
        table: `${tableStyles} border-separate border-spacing-[0] table-fixed w-full flex-1 text-c-table-light-text border-c-table-light-border group-hover:bg-c-table-light-rowCellHover dark:border-c-table-dark-border dark:text-c-table-dark-text`,
        row: 'flex-1 align-middle',
        verticalHeaderCell:
            'header-cell border-r border-t text-sm sm:text-base p-2 whitespace-nowrap md:px-4 text-left font-normal bg-c-table-light-verticalHeaderCell ' +
            'group-hover:bg-c-table-light-rowCellHover dark:bg-c-table-dark-verticalHeaderCell dark:group-hover:bg-c-table-dark-rowCellHover dark:border-c-table-dark-border',
        horizontalHeaderCell:
            'header-cell border-l border-r text-sm sm:text-base p-2 whitespace-nowrap md:px-4 text-left font-normal bg-c-table-light-horizontalHeaderCell ' +
            'group-hover:bg-c-table-light-rowCellHover dark:bg-c-table-dark-horizontalHeaderCell dark:group-hover:bg-c-table-dark-rowCellHover dark:border-c-table-dark-border',
        cell: 'cell border-r p-2 md:px-4 whitespace-nowrap text-sm sm:text-base font-semibold bg-white group-hover:bg-c-table-light-cellHover dark:bg-c-table-dark-cell dark:bg-c-table-dark-cell dark:border-c-table-dark-border dark:group-hover:bg-c-table-dark-cellHover',
        nameCell:
            'name-cell border-r truncate text-sm sm:text-base p-2 md:px-4 sm:space-x-2 rounded-tl border-t text-left text-white font-semibold bg-c-table-light-nameCell border-c-table-light-nameCell dark:bg-c-table-dark-nameCell dark:border-c-table-dark-border dark:text-c-table-dark-text',
        emphasize:
            'absolute top-0 w-full h-full animate-awos_emphasize_pulse pointer-events-none',
        icon: 'inline w-5 py-2.5 mr-1 fill-current dark:text-c-table-dark-text',
        lastRowCells: 'border-b dark:border-c-table-dark-border',
    };

    function loadingView(): JSX.Element {
        return (
            <tr data-testid="loading-view">
                <td className={styles.loadingView}>
                    <LoadingSpinner />
                </td>
            </tr>
        );
    }

    function generateTopBar(): JSX.Element {
        if (!isNil(data?.columns) || isNil(data?.name) || isNil(data?.icon)) {
            return <></>;
        }

        return (
            <div className={styles.topBar}>
                <AwosIcon iconName={data?.icon} styles={styles.icon} />
                <span className="align-middle font-semibold text-sm sm:text-base  dark:text-c-table-dark-text align-middle pl-2 h-10">
                    {data?.name}
                </span>
            </div>
        );
    }

    function generateHeaderCells(column: Column): JSX.Element | JSX.Element[] {
        return (
            <th
                key={`header-${column.id}`}
                className={`${styles.verticalHeaderCell} border-t border-r`}
            >
                {column.label}
            </th>
        );
    }

    const generateTooltipContent = (
        conversions: Conversions[] | undefined
    ): NonNullable<ReactNode> => {
        return get(conversions, 'length') ? (
            <div className="py-1">
                {(conversions || []).map(({ value, unit }) => (
                    <div
                        key={`tooltip${value}${unit}`}
                        className="grid grid-cols-2 gap-x-2 md:gap-x-8 gap-y-1 text-base"
                    >
                        <div className="text-white text-left col-span-1">
                            {value}
                        </div>
                        <div className="text-awos-white-4-230 text-right col-span-1">
                            {unit}
                        </div>
                    </div>
                ))}
            </div>
        ) : (
            ''
        );
    };

    function generateTupleCell(cell: Cell, isLast: boolean): JSX.Element {
        return (
            <td
                title={cell.name}
                key={`tuple-${cell.name}`}
                className={classNames(
                    'tuple-cell relative group p-0 m-0',
                    isLast && styles.lastRowCells
                )}
            >
                {cell.emphasize && (
                    <div
                        data-testid="emphasize"
                        className={`${styles.emphasize} -mb-px`}
                    >
                        {' '}
                    </div>
                )}
                <div
                    className={`${styles.horizontalHeaderCell} inline-block w-2/5 border-t`}
                >
                    {cell.name}
                </div>
                <Tooltip
                    key={`cell-tooltip-${cell.name}`}
                    title={generateTooltipContent(cell.conversions)}
                    childrenOnly={!get(cell, 'conversions', 0)}
                >
                    <div
                        title={cell.value}
                        className={`${styles.cell} p-2 inline-block w-3/5 border-t`}
                    >
                        {cell.value}
                    </div>
                </Tooltip>
            </td>
        );
    }

    function generateHeader(): JSX.Element | JSX.Element[] {
        if (isNil(data?.columns)) {
            return <></>;
        }

        return (
            <tr>
                <th
                    className={classNames(styles.nameCell, 'whitespace-nowrap')}
                >
                    <AwosIcon iconName={data?.icon} styles={styles.icon} />
                    <span title={data?.name} className="align-middle">
                        {data?.name}
                    </span>
                </th>
                {map(data?.columns, generateHeaderCells)}
            </tr>
        );
    }

    function generateTupleRow(
        row: Row,
        index: number,
        isLast: boolean
    ): JSX.Element {
        return (
            <tr key={`row-tuple-${index}`} className={styles.row}>
                {map(row.cells, (cell) => generateTupleCell(cell, isLast))}
            </tr>
        );
    }

    function generateCellByColumn(
        row: Row,
        column: Column,
        isLast: boolean
    ): JSX.Element {
        if (isNil(row.cells)) {
            return (
                <td
                    key={`empty-${column.id}-${row.name}`}
                    className={`${styles.cell} border-t ${
                        isLast && styles.lastRowCells
                    }`}
                />
            );
        }

        const cell = row.cells.filter((c) => c.column_id === column.id).pop();

        if (cell && cell.column_id) {
            return (
                <Tooltip
                    key={`cell-tooltip-${column.id}-${row.name}`}
                    title={generateTooltipContent(cell.conversions)}
                    childrenOnly={!get(cell, 'conversions', 0)}
                >
                    <td
                        title={cell.value}
                        key={`cell-${column.id}-${row.name}`}
                        className={`relative ${styles.cell} p-0 border-t ${
                            isLast && styles.lastRowCells
                        }`}
                    >
                        {cell.emphasize && (
                            <div
                                key={`cell-emphasize-${column.id}-${row.name}`}
                                data-testid="emphasize"
                                className={`${styles.emphasize} right-0`}
                            >
                                {' '}
                            </div>
                        )}
                        <span>{cell.value}</span>
                    </td>
                </Tooltip>
            );
        }

        return (
            <td
                key={`empty-${column.id}-${row.name}`}
                className={`${styles.cell} border-t ${
                    isLast && styles.lastRowCells
                }`}
            />
        );
    }

    function generateRow(row: Row, isLast: boolean): JSX.Element {
        return (
            <tr key={`row-${row.name}`} className={`${styles.row} group`}>
                <td
                    title={row.name}
                    className={`${styles.horizontalHeaderCell} border-t ${
                        isLast && styles.lastRowCells
                    }`}
                >
                    {row.name}
                </td>
                {data?.columns?.map((column) =>
                    generateCellByColumn(row, column, isLast)
                )}
            </tr>
        );
    }

    function generateBody(): JSX.Element | JSX.Element[] {
        if (isNil(data?.columns) && !isNil(data?.rows)) {
            return (
                data?.rows.map((row, index, rows) =>
                    generateTupleRow(row, index, index + 1 === rows.length)
                ) ?? []
            );
        }

        if (!isNil(data?.columns)) {
            return map(data?.rows, (row, index, rows) =>
                generateRow(row, index + 1 === rows.length)
            );
        }

        return loadingView();
    }

    function generateTable(): JSX.Element {
        return (
            <div className={classNames(styles.container, className)}>
                <SyncFailedSpinner isSyncFailed={isSyncFailed} />
                {generateTopBar()}
                <table className={styles.table} style={{ borderSpacing: 0 }}>
                    <thead>{generateHeader()}</thead>
                    <tbody>{generateBody()}</tbody>
                </table>
            </div>
        );
    }

    return generateTable();
};

Table.defaultProps = {
    data: {},
    containerStyles: '',
    tableStyles: '',
};

export default Table;
