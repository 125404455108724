import { AxiosResponse } from 'axios';
import { Redirect } from 'react-router';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState, FC } from 'react';

import { useGetData } from 'tools/hooks/getData';
import { Collapse } from 'pages/generic/collapse';
import { usePostData } from 'tools/hooks/postData';
import { useKeycloak } from 'tools/contexts/KeycloakContext';
import { useFeatureFlagsContext } from 'tools/contexts/FeatureFlagsContext';
import { showActionNotification } from 'pages/generic/showActionNotification';

import {
    DTO,
    Form,
    FormData,
    ActiveFormsData,
    ConvertedForRequestFormData,
} from './types';
import { convertFormEntries } from './helpers';
import { SettingsForm, LeaveModal } from './components';

const styles = {
    container: 'flex flex-col m-5 sm:mx-10 md:mx-14 lg:mx-16',
};

const Settings: FC = () => {
    const snackbar = useSnackbar();
    const { keycloak } = useKeycloak();
    const { fetchFlags } = useFeatureFlagsContext();
    const [formsData, setFormsData] = useState<ActiveFormsData>(
        {} as ActiveFormsData
    );
    const shouldPreventNavigation = Object.values(formsData).some(
        ({ isDirty }) => isDirty
    );

    const { data: settingsData, refetch } = useGetData<DTO | null>({
        retry: true,
        enabled: false,
        initialValue: null,
        endpoint: '/settings',
        queryName: 'settings',
    });

    const post = usePostData<AxiosResponse<ConvertedForRequestFormData>>(
        '/settings',
        {
            async onSuccess() {
                fetchFlags();
                await refetch();
            },
            onSettled(data, error) {
                showActionNotification({
                    snackbar,
                    isSuccessful: !error,
                    messages: {
                        success: 'Changes has been saved.',
                        fail:
                            (data as any) || // eslint-disable-line
                            'Changes has not been saved. Error occured',
                    },
                });
            },
        }
    );

    useEffect(() => {
        refetch();
    }, [keycloak]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!keycloak?.authenticated) return <Redirect to="/" />;

    const handleSubmit = async (formData: FormData, form: Form) => {
        return post.mutate(convertFormEntries(formData, form));
    };
    const submitBeforeLeave = async () => {
        const mutations: Promise<AxiosResponse<ConvertedForRequestFormData>>[] =
            [];
        Object.entries(formsData).forEach(([formId, { data, isDirty }]) => {
            if (isDirty && settingsData) {
                const matchedForm = settingsData.forms.find(
                    ({ id }) => formId === id
                );

                if (matchedForm) {
                    mutations.push(
                        post.mutateAsync(convertFormEntries(data, matchedForm))
                    );
                }
            }
        });

        const results = await Promise.all(mutations);

        return results.every(({ status }) => status === 200);
    };

    if (settingsData) {
        return (
            <div className={styles.container}>
                <Collapse accordion>
                    {settingsData.forms.map((form) => (
                        <SettingsForm
                            onSubmit={handleSubmit}
                            onChange={setFormsData}
                            form={form}
                            key={form.id}
                        />
                    ))}
                </Collapse>
                <LeaveModal
                    onSubmit={submitBeforeLeave}
                    shouldPrevent={shouldPreventNavigation}
                />
            </div>
        );
    }

    return null;
};

export { Settings };
