import {
    ArrayParam,
    NumberParam,
    StringParam,
    BooleanParam,
    useQueryParams,
} from 'use-query-params';
import moment from 'moment';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

import fetchWithTimeout from 'tools/hooks/common';
import { Button } from 'pages/generic/form/Button';
import QueryInput from 'pages/generic/form/QueryInput';
import { DateTime } from 'pages/generic/form/date-time';
import { useKeycloak } from 'tools/contexts/KeycloakContext';
import { showActionNotification } from 'pages/generic/showActionNotification';

import './action-bar.css';
import { ReportMultiselect } from './ReportMultiselect';

const ActionBar: React.FunctionComponent = () => {
    const [isFiltersOpen, toggleFilters] = useState(false);
    const [fromDateTime, setFromDateTime] = useState<moment.Moment | undefined>(
        undefined
    );
    const styles = {
        actionBar:
            'pt-6 px-1 md:px-2 lg:px-3 border-awos-grayscale-38 bg-awos-white-2 dark:bg-awos-black-3',
        infoSpan: 'text-awos-navy-2 dark:text-awos-gray-10',
        filtersWraper: `${
            isFiltersOpen ? 'max-h-screen' : 'max-h-0'
        } transition-all`,
        filtersSubWraper: `${
            isFiltersOpen ? 'opacity-100' : 'opacity-0'
        } transition-opacity`,
    };

    const snackbar = useSnackbar();
    const { authHeader } = useKeycloak();

    const [queryParam] = useQueryParams({
        to_date_time: NumberParam,
        from_date_time: NumberParam,
        report_type: ArrayParam,
        per_page: NumberParam,
        page: NumberParam,
        desc_sorting: BooleanParam,
        search: StringParam,
    });

    const downloadReportsCSV = useCallback(() => {
        let isSuccessful = false;
        fetchWithTimeout(`/api/reports/csv`, {
            method: 'POST',
            body: JSON.stringify(queryParam),
            timeout: 1000,
            headers: authHeader,
        })
            .then((r) => {
                if (r.ok) return r.blob();
                return undefined;
            })
            .then((blob) => {
                if (blob) {
                    const csvURL = window.URL.createObjectURL(blob);
                    const tempLink = document.createElement('a');
                    tempLink.href = csvURL;
                    tempLink.setAttribute(
                        'download',
                        `reports_${moment().format('DD/MM/YYYY_HH:mm:ss')}.csv`
                    );
                    tempLink.click();
                    isSuccessful = true;
                }
            })
            .finally(() => {
                showActionNotification({
                    snackbar,
                    isSuccessful,
                    messages: {
                        success: 'Download completed.',
                        fail: 'Cannot download reports CSV file.',
                    },
                });
            });
        // eslint-disable-next-line
    }, [queryParam, authHeader]);

    const toggleTableFilters = useCallback(() => {
        toggleFilters(!isFiltersOpen);
    }, [isFiltersOpen]);

    return (
        <div className={classNames(styles.actionBar, 'action-bar-wrapper')}>
            <div className="action-bar-search">
                <QueryInput
                    placeholder="Search..."
                    queryName="search"
                    className="max-w-xs"
                />
            </div>
            <div
                className={classNames(
                    styles.filtersWraper,
                    { isFiltersOpen },
                    'filters-wrapper action-bar-filters'
                )}
            >
                <div
                    className={classNames(
                        styles.filtersSubWraper,
                        'filters-subwrapper'
                    )}
                >
                    <div className="relative w-full date-time">
                        <p
                            className={classNames(
                                'action-bar-filter-label',
                                styles.infoSpan
                            )}
                        >
                            From
                        </p>
                        <DateTime
                            queryName="from_date_time"
                            setDateTime={setFromDateTime}
                        />
                    </div>
                    <div className="relative w-full date-time">
                        <p
                            className={classNames(
                                'action-bar-filter-label',
                                styles.infoSpan
                            )}
                        >
                            To
                        </p>
                        <DateTime
                            queryName="to_date_time"
                            minDate={fromDateTime}
                        />
                    </div>
                    <div className="relative w-full ">
                        <p
                            className={classNames(
                                'action-bar-filter-label',
                                styles.infoSpan
                            )}
                        >
                            Report type
                        </p>
                        <ReportMultiselect />
                    </div>
                </div>
            </div>
            <div className="action-bar-actions flex">
                <Button
                    className="action-bar-filter mr-2"
                    iconName="export"
                    onClick={toggleTableFilters}
                >
                    Filters
                </Button>
                <Button
                    iconName="export"
                    onClick={downloadReportsCSV}
                >
                    Export
                </Button>
            </div>
        </div>
    );
};

export { ActionBar };
