import { useSnackbar } from 'notistack';
import { findIndex, unionBy } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';

import {
    useReportNotify,
    WebsocketMessage,
} from 'tools/contexts/reportNotificationContext';
import {
    ModalEvents,
    REPORT_CHANNEL,
    REPORT_STORAGE_KEY,
    REPORT_WINDOW_NAME,
    NotificationReason,
    ReportStorageReason,
    REPORT_STORAGE_TEMPLATE_KEY,
} from 'consts';
import { getWithExpiry, setWithExpiry, isModalWindow } from 'helpers';

import { NotificationType, ReportChannelMessage } from './types';

const CHECK_UNMOUNT_DELAY = 1000;

export const ReportNotificator: FC = () => {
    const {
        isMainTab,
        websocket,
        setTabToMain,
        reportsQueue,
        reportPending,
        setReportPending,
        reportModalConfig,
        updateReportsQueue,
        setReportModalConfig,
    } = useReportNotify();
    const isNotModal = !isModalWindow();
    const [showModal, setShowModal] = useState(false);
    const [channel, setChannel] = useState<BroadcastChannel | null>(null);
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();

    const closeWindow = useCallback(() => {
        setShowModal(false);
        setReportPending(false);
    }, [setShowModal, setReportPending]);

    const RSKObserver = (tries = 0) => {
        const val = getWithExpiry(REPORT_STORAGE_KEY);
        if (val) {
            setTimeout(RSKObserver, 2 * CHECK_UNMOUNT_DELAY);
        } else if (tries < 2) {
            setTimeout(() => RSKObserver(tries + 1), CHECK_UNMOUNT_DELAY);
        } else {
            channel?.postMessage({ type: ModalEvents.CLOSE });
            closeWindow();
        }
    };

    useEffect(() => {
        if (reportsQueue.length) {
            if (reportPending) {
                const index = findIndex(
                    reportsQueue,
                    (report) =>
                        report.notificationReason ===
                        NotificationReason.REPORT_SCHEDULED
                );

                if (index >= 0 && reportModalConfig.special) {
                    updateReportsQueue((prevState) => {
                        const queue = prevState;
                        const firstNotSpecial = queue.splice(index, 1)[0];
                        queue.unshift(firstNotSpecial);

                        return queue;
                    });
                    closeWindow();
                }
            }
            if (!reportPending) {
                const firstReportInQueue = reportsQueue[0];
                setShowModal(true);
                setReportPending(true);
                setReportModalConfig(firstReportInQueue);
                closeSnackbar(firstReportInQueue.reportId);
                updateReportsQueue(() => reportsQueue.slice(1));
                if (isMainTab.currMain) {
                    setWithExpiry(
                        REPORT_STORAGE_TEMPLATE_KEY,
                        JSON.stringify(firstReportInQueue),
                        CHECK_UNMOUNT_DELAY
                    );
                    setTabToMain({ currMain: true, prevMain: true });
                }
            }
        }
        // eslint-disable-next-line
    }, [
        showModal,
        closeWindow,
        setShowModal,
        reportsQueue,
        reportPending,
        closeSnackbar,
        setReportPending,
        updateReportsQueue,
        isMainTab.currMain,
        setReportModalConfig,
        reportModalConfig.special,
    ]);

    useEffect(() => {
        if (showModal) {
            const width = 700;
            const height = 850;
            const left = window.screen.width / 2 - width / 2;
            const top = window.screen.height / 2 - height / 2;
            if (isMainTab.currMain && isMainTab.prevMain) {
                setWithExpiry(
                    REPORT_STORAGE_KEY,
                    ReportStorageReason.WINDOW_PREOPEN,
                    5
                );
                window.open(
                    '/report-modal',
                    REPORT_WINDOW_NAME,
                    `directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,width=${width},height=${height},top=${top},left=${left}`
                );
            }
        }
    }, [showModal, isMainTab]);

    useEffect(() => {
        if (isNotModal) {
            const isKeyPresent = Boolean(getWithExpiry(REPORT_STORAGE_KEY));
            const channelInstance = new BroadcastChannel(REPORT_CHANNEL);
            const elector = createLeaderElection(channelInstance);
            getWithExpiry('rskv');
            setReportPending(isKeyPresent);
            setShowModal(isKeyPresent);
            setChannel(() => {
                channelInstance.postMessage({ type: ModalEvents.SHARE });
                return channelInstance;
            });

            elector.awaitLeadership().then(() => {
                if (isKeyPresent) {
                    RSKObserver();
                }
                setTabToMain({ currMain: true, prevMain: false });
                channelInstance.postMessage({
                    type: ModalEvents.LIDER_SELECTED,
                });
            });
        }
        // eslint-disable-next-line
    }, [isNotModal, setReportPending]);

    useEffect(() => {
        if (isNotModal && isMainTab.currMain) {
            const onStorageChange = ({ key }: StorageEvent) => {
                if (!key) {
                    RSKObserver();
                }
            };
            window.addEventListener('storage', onStorageChange);

            return () => {
                window.removeEventListener('storage', onStorageChange);
            };
        }
        // eslint-disable-next-line
    }, [channel, isNotModal, isMainTab.currMain]);

    useEffect(() => {
        const notifyWindowStateWebsocket = (isOpen: boolean) => {
            if (
                websocket !== undefined &&
                websocket.OPEN === websocket.readyState
            ) {
                const msg: WebsocketMessage = {
                    message_type: 'report-window-state',
                    data: { open: isOpen },
                };
                websocket?.send(JSON.stringify(msg));
            }
        };

        if (channel && isNotModal) {
            channel.onmessage = ({
                type,
                currentReportsQueue,
                isPending,
            }: ReportChannelMessage) => {
                if (type === ModalEvents.CLOSE) {
                    notifyWindowStateWebsocket(false);
                    return closeWindow();
                }

                if (type === ModalEvents.ALIVE && showModal) {
                    notifyWindowStateWebsocket(true);
                    return;
                }

                if (type === ModalEvents.LIDER_SELECTED) {
                    return setTabToMain({
                        currMain: false,
                        prevMain: isMainTab.currMain,
                    });
                }

                if (type === ModalEvents.SHARE) {
                    if (isMainTab.currMain) {
                        return channel.postMessage({
                            currentReportsQueue: reportsQueue,
                            isPending: reportPending,
                        });
                    }

                    return setTabToMain({
                        currMain: false,
                        prevMain: isMainTab.currMain,
                    });
                }

                if (currentReportsQueue && !isMainTab.currMain) {
                    const unique = currentReportsQueue.filter(
                        ({ reportId }: NotificationType) => {
                            return !(
                                findIndex(
                                    reportsQueue,
                                    (reportNotification) => {
                                        return (
                                            reportId ===
                                            reportNotification.reportId
                                        );
                                    }
                                ) + 1
                            );
                        }
                    );

                    setShowModal(showModal);
                    setReportPending(isPending);
                    updateReportsQueue(
                        unionBy(reportsQueue, unique, 'reportId')
                    );

                    unique.forEach(
                        ({ reportId, notificationTitle }: NotificationType) => {
                            enqueueSnackbar(<></>, {
                                key: reportId,
                                persist: true,
                                content: () => (
                                    <div
                                        role="alert"
                                        className="p-3 rounded bg-awos-black-1 text-awos-gray-1 text-sm shadow-md dark:bg-awos-white-3 dark:text-awos-gray-15"
                                    >
                                        <span>{notificationTitle}</span>
                                    </div>
                                ),
                            });
                        }
                    );
                }
            };
        }
        // eslint-disable-next-line
    }, [
        channel,
        websocket,
        showModal,
        isMainTab,
        isNotModal,
        reportsQueue,
        reportPending,
        reportModalConfig,
    ]);

    return null;
};
