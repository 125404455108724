import classNames from 'classnames';
import { isEqual, debounce } from 'lodash';
import SL, { GroupBase, MultiValue as MultiValueType } from 'react-select';
import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useState,
    memo,
} from 'react';

import { useDarkMode } from 'tools/contexts/DarkModeContext';

import './select.css';
import { MultiSelectProps, SelectOption, BaseMenuProps } from './types';
import {
    DropdownIndicator,
    Option,
    MultiValue,
    ValueContainer,
    Menu,
} from './common';
import {
    getSelectStyles,
    matchValuesToMultiselectOptions,
    getColorTheme,
} from './helpers';

const DEBOUNCE_TIME = 200;

export const MultiSelect: FC<MultiSelectProps> = memo(
    ({
        options,
        onChange,
        className,
        disabled = false,
        value: selectedValue = [],
    }: // added explicit props typing here because eslint(react/prop-types) throws warning when FC wrapped in memo
    MultiSelectProps) => {
        const { darkMode } = useDarkMode();
        const [menuPlacement, setMenuPlacement] = useState<string>('');
        const colourTheme = useMemo(() => getColorTheme(darkMode), [darkMode]);
        const [selectedOptions, setSelectedOptions] =
            useState<MultiSelectProps['value']>(selectedValue);
        const selectStyles = useMemo(
            () => getSelectStyles(darkMode, menuPlacement),
            [darkMode, menuPlacement]
        );
        const value = useMemo(
            () => matchValuesToMultiselectOptions(options, selectedOptions),
            [options, selectedOptions]
        );
        const ExtendedMenu = useCallback(
            (menuProps: BaseMenuProps) => (
                <Menu {...menuProps} setMenuPlacement={setMenuPlacement} />
            ),
            []
        );

        // Have to use useMemo here instead of useCallback because useCallback accepts inline function,
        // but onChange callback need to be debounced
        const debouncedHandler = useMemo(
            () =>
                debounce((newlySelected) => {
                    if (onChange) {
                        onChange(newlySelected);
                    }
                }, DEBOUNCE_TIME),
            [onChange]
        );

        const onChangeHandler = useCallback(
            (newValues: MultiValueType<SelectOption>) => {
                setSelectedOptions(
                    newValues.map(({ value: newValue }) => newValue)
                );
            },
            [setSelectedOptions]
        );

        useEffect(() => {
            debouncedHandler(selectedOptions);
        }, [selectedOptions, debouncedHandler]);

        useEffect(() => {
            if (!isEqual(selectedOptions, selectedValue)) {
                setSelectedOptions(selectedValue);
            }
            // This effect should fire only when outer value changed.
            // That why selectedOptions(inner state value) is not added to deps array.
        }, [selectedValue]); // eslint-disable-line react-hooks/exhaustive-deps

        return (
            <SL<SelectOption, true, GroupBase<SelectOption>>
                isMulti
                value={value}
                options={options}
                placeholder="None"
                theme={colourTheme}
                isClearable={false}
                menuPlacement="auto"
                isDisabled={disabled}
                styles={selectStyles}
                closeMenuOnSelect={false}
                onChange={onChangeHandler}
                hideSelectedOptions={false}
                classNamePrefix="react-select"
                className={classNames(
                    className,
                    `react-select-${menuPlacement}`
                )}
                components={{
                    Option,
                    DropdownIndicator,
                    MultiValue,
                    ValueContainer,
                    Menu: ExtendedMenu,
                }}
            />
        );
    }
);
