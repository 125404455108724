import { FC } from 'react';
import classNames from 'classnames';

import AwosIcon from 'pages/generic/AwosIcon';
import { Card, CardI } from 'pages/generic/card';
import BasicWindowContainer from 'pages/generic/BasicWindowContainer';
import { SyncFailedSpinner } from 'pages/generic/syncFailedSpinner';

import './WeatherWidget.css';

const styles = {
    cardsWrapper: 'flex flex-wrap',
    reportIcon: 'inline w-5 py-2.5 mr-1 ml-1 mr-5',
};

const EmptyNode = (
    <div className="text-awos-navy-2 dark:text-gray-300 text-left p-5 h-32">
        No weather widgets available
    </div>
);

export type WeatherWidgetProps = {
    data?: CardI[];
    isSyncFailed?: boolean;
};

export const WeatherWidget: FC<WeatherWidgetProps> = ({
    data,
    isSyncFailed = false,
}) => {
    const cards = data;
    return (
        <BasicWindowContainer
            styles={{
                topBar: 'py-0',
                container: 'border relative',
            }}
            topLeft={
                <span className="text-sm sm:text-base font-semibold">
                    <AwosIcon iconName="plane" styles={styles.reportIcon} />
                    <span>Weather</span>
                </span>
            }
            className="weather-widget"
        >
            <div
                className={classNames(
                    styles.cardsWrapper,
                    'weather-cards-wrapper',
                    { 'emty-weather-cards-wrapper': !cards?.length }
                )}
            >
                {cards?.length
                    ? cards.map((card) => (
                          <Card
                              key={`${card.title}${card.value.join}`}
                              {...card}
                              className="weather-card"
                          />
                      ))
                    : EmptyNode}
            </div>
            <SyncFailedSpinner isSyncFailed={isSyncFailed} />
        </BasicWindowContainer>
    );
};
