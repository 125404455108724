import React, { FC } from 'react';
import { useSnackbar } from 'notistack';

import { Roles } from 'consts';
import { useGetData } from 'tools/hooks/getData';
import { usePostData } from 'tools/hooks/postData';
import { useKeycloak } from 'tools/contexts/KeycloakContext';
import { showActionNotification } from 'pages/generic/showActionNotification';
import {
    Select,
    SelectProps,
    matchValueToSelectOption,
} from 'pages/generic/form/select';

type ActiveRunwayData = {
    label: string;
    icao: string;
    component_type: 'select';
    component: {
        value: SelectProps['value'];
        options: SelectProps['options'];
    };
};

export const ActiveRunway: FC = () => {
    const snackbar = useSnackbar();
    const { checkRoles } = useKeycloak();
    const activeRunwayURL = '/settings/active-runway';

    const { data, refetch } = useGetData<ActiveRunwayData | null>({
        enabled: true,
        initialValue: null,
        intervalMs: 2 * 1000,
        endpoint: activeRunwayURL,
        queryName: 'activeRunway',
    });

    const post = usePostData(activeRunwayURL, {
        onSuccess() {
            refetch();
        },
        onSettled(responseData, error) {
            showActionNotification({
                snackbar,
                isSuccessful: !error,
                messages: {
                    success: 'Active runway changed.',
                    fail: 'Application could not save your changes.',
                },
            });
        },
    });

    if (data) {
        const { icao, component } = data;

        if (checkRoles({ included: [Roles.SET_ACTIVE_RUNWAY] })) {
            const handleChange = (value: string) => {
                post.mutate({ icao, value });
            };

            return (
                <Select
                    borders={false}
                    isSearchable={false}
                    onChange={handleChange}
                    value={component.value}
                    options={component.options ?? []}
                />
            );
        }

        return (
            <p>
                {
                    matchValueToSelectOption(component.options, component.value)
                        ?.label
                }
            </p>
        );
    }

    return null;
};
