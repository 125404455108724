import React from 'react';
import { ProviderContext } from 'notistack';

import AwosIcon, { IconNames } from 'pages/generic/AwosIcon';

export const showActionNotification = ({
    messages,
    isSuccessful,
    snackbar: { closeSnackbar, enqueueSnackbar },
}: {
    isSuccessful: boolean;
    snackbar: ProviderContext;
    messages: { success: string; fail: string };
}): void => {
    const styles = {
        link: 'ml-4',
        icon: 'inline w-5 mr-2.5',
        wrapper: `
            p-3 rounded bg-awos-black-1 text-awos-gray-1 text-sm 
            shadow-md dark:bg-awos-white-3 dark:text-awos-gray-15`,
    };

    const Notification = ({
        iconName,
        message,
    }: {
        message: string;
        iconName: IconNames;
    }) => {
        return (
            <>
                <AwosIcon iconName={iconName} styles={styles.icon} />
                {message}
            </>
        );
    };

    const notificationProps = {
        iconName: isSuccessful ? 'accept' : 'decline',
        message: isSuccessful ? messages.success : messages.fail,
    };

    const notificationComponent = Notification(notificationProps);

    enqueueSnackbar(<></>, {
        content: (notificationId) => (
            <div role="alert" className={styles.wrapper}>
                {notificationComponent}
                <button
                    type="button"
                    className={styles.link}
                    onClick={(event) => {
                        event.stopPropagation();
                        closeSnackbar(notificationId);
                    }}
                >
                    Close
                </button>
            </div>
        ),
    });
};
