import { Redirect } from 'react-router';
import React, { useEffect, useRef, useState } from 'react';

import { useKeycloak } from 'tools/contexts/KeycloakContext';

type DashboardsProps = {
    darkMode: string;
};

const Dashboards: React.FunctionComponent<DashboardsProps> = ({
    darkMode,
}: DashboardsProps) => {
    const { keycloak } = useKeycloak();
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const grafanaLocalStorageKey = 'grafana_href';
    const defaultHref = `${window.location.origin}/grafana/?kiosk=tv`;
    const [grafanaSrc, setGrafanaSource] = useState(defaultHref);

    const attachMutationObserver = () => {
        // Get the iframe body
        const iframeBody = iframeRef.current?.contentWindow?.document.body;
        // Setup the config
        const config = {
            childList: true,
            subtree: true,
        };
        // Create a callback
        const callback = () => {
            if (iframeRef.current?.contentWindow?.location.href) {
                localStorage.setItem(
                    grafanaLocalStorageKey,
                    iframeRef.current?.contentWindow?.location.href.toString()
                );
            }
        };

        // Watch the iframe for changes
        const observer: MutationObserver = new MutationObserver(callback);
        observer.observe(iframeBody as Node, config);
    };

    useEffect(() => {
        const generateGrafanaSrc = (): string => {
            const storedHref = localStorage.getItem(grafanaLocalStorageKey);
            const grafanaSrcURL = new URL(storedHref || defaultHref);

            // set theme
            grafanaSrcURL.searchParams.set(
                'theme',
                `${darkMode === 'dark' ? 'dark' : 'light'}`
            );

            return grafanaSrcURL.toString();
        };

        setGrafanaSource(generateGrafanaSrc());
    }, [darkMode, defaultHref]);

    if (!keycloak?.authenticated) return <Redirect to="/" />;

    return (
        <div className="flex h-app">
            <iframe
                id="iframe_id"
                className="flex-1"
                title="Grafana Dashboard"
                src={grafanaSrc}
                ref={iframeRef}
                onLoad={attachMutationObserver}
            />
        </div>
    );
};

export default Dashboards;
