import React, {
    FC,
    useMemo,
    Dispatch,
    useState,
    useEffect,
    SetStateAction,
} from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { isEqual, isString, trim, mapValues } from 'lodash';

import AwosIcon from 'pages/generic/AwosIcon';
import { Panel } from 'pages/generic/collapse';
import { Button } from 'pages/generic/form/Button';

import { generateComponent } from './helpers';
import moduleCSS from './settings-form.module.css';
import { Form, FormData, ActiveFormsData } from '../types';

const styles = {
    panelHeader: 'h-10 box-content',
    field: {
        container: 'flex',
        label: 'w-36 sm:w-44 flex text-sm mr-2 items-center',
        input: 'flex-1 flex justify-start',
    },
    form: {
        form: 'flex flex-1',
        windowSpan:
            'text-sm sm:text-base inline font-semibold flex items-center',
        reportIcon: 'inline w-4 py-1 ml-1 mr-3 md:mr-5',
        container:
            'm-5 pb-5 grid gap-5 text-awos-navy-2 dark:text-awos-white-3',
        submitButtonContainer:
            'mt-auto flex flex-row col-span-full submitButtonContainer',
    },
};

export type SettingsFormProps = {
    form: Form;
    onSubmit: (formData: FormData, form: Form) => void;
    onChange: Dispatch<SetStateAction<ActiveFormsData>>;
};

export const SettingsForm: FC<SettingsFormProps> = ({
    form,
    onSubmit,
    onChange,
    ...restProps
}) => {
    const defaultValues = useMemo(() => {
        const formData: FormData = {};
        form.fields.forEach((field) => {
            let value: FormData['inputId'];
            if (field.component_type === 'checkbox') {
                value = field.component.checked ?? false;
            } else {
                value = field.component.value ?? '';
            }

            formData[field.id.name] = value;
        });

        return formData;
    }, [form]);

    const formRegistering = useForm({ defaultValues });
    const { watch, reset } = formRegistering;
    const formData = mapValues(watch(), (value) => {
        return isString(value) ? trim(value) : value;
    });
    const isButtonHidden = isEqual(formData, defaultValues);
    const [shouldRenderButton, setShouldRenderButton] = useState(
        !isButtonHidden
    );

    useEffect(() => {
        if (!isButtonHidden) {
            setShouldRenderButton(true);
        }
    }, [isButtonHidden]); // eslint-disable-line react-hooks/exhaustive-deps

    const values = Object.values(formData).join('');
    useEffect(() => {
        onChange((formsData: ActiveFormsData) => {
            return {
                ...formsData,
                [form.id]: {
                    isDirty: !isButtonHidden,
                    data: formData,
                },
            };
        });
    }, [values, isButtonHidden]); // eslint-disable-line react-hooks/exhaustive-deps

    const header = useMemo(
        () => (
            <span className={styles.form.windowSpan}>
                <AwosIcon
                    iconName={form.icon}
                    styles={styles.form.reportIcon}
                />
                {form.name.toUpperCase()}
            </span>
        ),
        [form]
    );

    const buttons = useMemo(() => {
        const submitHandler = (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ) => {
            if (!isButtonHidden) {
                event.stopPropagation();
                onSubmit(formData, form);
            }
        };

        const restoreHandler = (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ) => {
            event.stopPropagation();
            reset(defaultValues);
        };

        if (shouldRenderButton) {
            return (
                <div
                    onAnimationEnd={() => setShouldRenderButton(false)}
                    className={classNames(
                        styles.form.submitButtonContainer,
                        isButtonHidden &&
                            `${moduleCSS.settingsFormSubmitButtonHidden} content-hidden`,
                        !isButtonHidden &&
                            moduleCSS.settingsFormSubmitButtonVisible
                    )}
                >
                    <Button
                        iconName="accept"
                        onClick={submitHandler}
                        className={classNames(moduleCSS.settingsButton)}
                    >
                        <span className={moduleCSS.submitChangesText}>
                            SAVE <span>CHANGES</span>
                        </span>
                    </Button>
                    <Button
                        iconName="decline"
                        onClick={restoreHandler}
                        className={classNames(moduleCSS.settingsButton, 'pl-2')}
                    >
                        <span className={moduleCSS.submitChangesText}>
                            RESTORE
                        </span>
                    </Button>
                </div>
            );
        }

        return null;
    }, [
        form,
        reset,
        onSubmit,
        formData,
        defaultValues,
        isButtonHidden,
        shouldRenderButton,
    ]);

    return (
        <>
            <Panel
                {...restProps}
                header={header}
                extra={buttons}
                headerClass={styles.panelHeader}
            >
                <form
                    data-testid={`form-${form.name}`}
                    spellCheck={form.spellCheck ?? false}
                    className={classNames(
                        styles.form.form,
                        moduleCSS.settingsForm
                    )}
                >
                    <div className={styles.form.container}>
                        {form.fields.map((field) => {
                            return (
                                <div
                                    key={field.id.name}
                                    className={styles.field.container}
                                >
                                    <label
                                        className={styles.field.label}
                                        htmlFor={field.id.name}
                                    >
                                        {field.label}
                                    </label>
                                    <div className={styles.field.input}>
                                        {generateComponent(
                                            field,
                                            formRegistering
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </form>
            </Panel>
        </>
    );
};
