import React from 'react';
import moment from 'moment';

import { Record } from './ReportTypes';

type ReportRecordProps = {
    data: Record;
};

const styles = {
    nameCell: 'h-10 text-left pl-2.5 text-sm sm:text-base',
    dateCell: 'h-10 pl-2.5 text-sm sm:text-base',
    recordRow:
        'bg-white hover:bg-awos-white-2 dark:bg-awos-gray-15 dark:hover:bg-awos-black-4 border-b border-awos-grayscale-38',
};

const ReportRecord: React.FunctionComponent<ReportRecordProps> = ({
    data,
}: ReportRecordProps) => {
    return (
        <tr key={data.id} className={styles.recordRow}>
            <td key={`date_cell_${data.id}`} className={styles.dateCell}>
                {moment
                    .unix(data.timestamp)
                    .utc()
                    .format('DD.MM.YYYY HH:mm:ss')}
            </td>
            <td key={`name_cell_${data.id}`} className={styles.nameCell}>
                {data.data}
            </td>
        </tr>
    );
};

export default ReportRecord;
