import { FC } from 'react';
import { get } from 'lodash';
import classNames from 'classnames';
import { Redirect } from 'react-router';

import { useKeycloak } from 'tools/contexts/KeycloakContext';
import { ProtectFeatureByFlag } from 'tools/protectors';
import { FeatureFlags } from 'consts';

import {
    SidePanel,
    FooterReport,
    WeatherWidget,
    TelegramTable,
    ReportGenerationWidget,
} from '../components';
import {
    useHomeDataContext,
    HomeDataContextProvider,
} from './smallStationHomeDataContextProvider';

import './SmallStationHome.css';

const containerStyles = [
    'text-center',
    'small-station-home-page',
    'm-5 sm:mx-10 md:mx-14 lg:mx-16',
    'flex flex-1 flex-col',
].join(' ');

const Home: FC = () => {
    const { keycloak } = useKeycloak();
    const { windrose, reportList, tafElementsList, weatherCards } =
        useHomeDataContext();

    if (!keycloak?.authenticated) {
        return <Redirect to="/" />;
    }

    return (
        <div className={containerStyles}>
            <div className="small-station-home-page-grid grid mb-4">
                <div
                    className={classNames('wind-rose', {
                        'active-rose': get(
                            windrose,
                            'data.runway_active',
                            false
                        ),
                    })}
                >
                    <SidePanel windrose={windrose} />
                </div>
                <div className="weather-area">
                    <WeatherWidget {...weatherCards} />
                </div>
                <div className="statistic-area">
                    <ProtectFeatureByFlag flag={FeatureFlags.TAF}>
                        <ReportGenerationWidget
                            widgetName="TAF"
                            isSyncFailed={tafElementsList?.isSyncFailed}
                        >
                            <TelegramTable data={tafElementsList?.data} />
                        </ReportGenerationWidget>
                    </ProtectFeatureByFlag>
                </div>
            </div>
            <div className="relative footer-report">
                <FooterReport {...reportList} />
            </div>
        </div>
    );
};

export const SmallStationHome: FC = () => (
    <HomeDataContextProvider>
        <Home />
    </HomeDataContextProvider>
);
