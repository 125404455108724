import React, {
    FC,
    Dispatch,
    useState,
    useEffect,
    useContext,
    useCallback,
    createContext,
    SetStateAction,
} from 'react';
import { intersection, get } from 'lodash';

import { Roles } from 'consts';
import { safelyParseJSON } from 'helpers';

interface KeycloakContextT {
    initialized: boolean;
    authHeader: {
        Authorization: string;
    };
    checkRoles(roles: { included?: Roles[]; excluded?: Roles[] }): boolean;
    keycloak: Keycloak.KeycloakInstance;
    tokenRefreshed: boolean;
    setTokenRefreshed: Dispatch<SetStateAction<boolean>>;
}

const KeycloakContext = createContext<KeycloakContextT>({
    initialized: false,
    checkRoles: () => false,
    authHeader: { Authorization: '' },
    keycloak: {} as Keycloak.KeycloakInstance,
    tokenRefreshed: true,
    setTokenRefreshed: () => {},
});

type KeycloakProviderT = {
    LoadingComponent: JSX.Element;
    authClient: Keycloak.KeycloakInstance;
    children: JSX.Element | JSX.Element[];
};

const isUserAuthenticated = (authClient: Keycloak.KeycloakInstance) =>
    !!authClient.idToken && !!authClient.token;

const KEYCLOAK_TOKEN_KEYS = 'kcc';

export const KeycloakProvider: FC<KeycloakProviderT> = ({
    children,
    authClient,
    LoadingComponent,
}) => {
    const [authState, setAuthState] = useState({
        initialized: false,
        isAuthenticated: false,
    });

    const updateAuthState = useCallback(() => {
        const isAuthenticated = isUserAuthenticated(authClient);
        if (isAuthenticated) {
            window.localStorage.setItem(
                KEYCLOAK_TOKEN_KEYS,
                JSON.stringify({
                    token: authClient.token,
                    refreshToken: authClient.refreshToken,
                    idToken: authClient.idToken,
                })
            );
            setAuthState({
                isAuthenticated,
                initialized: true,
            });
        } else {
            authClient.login();
        }
    }, [authClient]);

    const [tokenRefreshed, setTokenRefreshed] = useState<boolean>(true);

    const refreshToken = useCallback(() => {
        authClient.updateToken(5).then((refreshed) => {
            setTokenRefreshed(refreshed);
        });
    }, [authClient]);

    useEffect(() => {
        const oldTokens = safelyParseJSON(
            window.localStorage.getItem(KEYCLOAK_TOKEN_KEYS),
            {}
        );
        authClient.onTokenExpired = refreshToken;
        authClient.onAuthLogout = updateAuthState;
        authClient.onAuthRefreshSuccess = updateAuthState;
        authClient
            .init({
                ...oldTokens,
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: `/silent-check-sso.html`,
            })
            .then(updateAuthState);
        // eslint-disable-next-line
    }, []);

    const { initialized } = authState;

    if (!initialized) {
        return LoadingComponent;
    }

    const authHeader = {
        Authorization: authClient.token ? `Bearer ${authClient.token}` : '',
    };

    const checkRoles: KeycloakContextT['checkRoles'] = (roles) => {
        const userRoles = get(authClient, ['realmAccess', 'roles'], []);
        if (roles.excluded?.length) {
            return intersection(roles.excluded, userRoles).length === 0;
        }

        return (
            intersection(
                roles.included,
                get(authClient, ['realmAccess', 'roles'], [])
            ).length > 0
        );
    };

    return (
        <KeycloakContext.Provider
            value={{
                authHeader,
                checkRoles,
                initialized,
                keycloak: authClient,
                tokenRefreshed,
                setTokenRefreshed,
            }}
        >
            {children}
        </KeycloakContext.Provider>
    );
};

export const useKeycloak = (): KeycloakContextT => useContext(KeycloakContext);
