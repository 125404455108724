import React, { FC } from 'react';
import { Collapse } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { keycloak } from 'keycloak';
import LoadPage from 'pages/generic/LoadPage';
import { KeycloakProvider } from 'tools/contexts/KeycloakContext';
import KeycloakLoadingScreen from 'pages/generic/KeycloakLoadingScreen';
import { DarkModeContextProvider } from 'tools/contexts/DarkModeContext';
import { ReportNotifyContextProvider } from 'tools/contexts/reportNotificationContext';

import './assets/App.css';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const App: FC = () => {
    return (
        <Router>
            <div className="flex flex-col ease-in-out bg-awos-white-1 dark:bg-awos-black-2">
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    maxSnack={15}
                    TransitionComponent={Collapse}
                >
                    <KeycloakProvider
                        authClient={keycloak}
                        LoadingComponent={<KeycloakLoadingScreen />}
                    >
                        <QueryClientProvider client={queryClient}>
                            <ReportNotifyContextProvider>
                                <DarkModeContextProvider>
                                    <LoadPage />
                                </DarkModeContextProvider>
                            </ReportNotifyContextProvider>
                        </QueryClientProvider>
                    </KeycloakProvider>
                </SnackbarProvider>
            </div>
        </Router>
    );
};

export default App;
