import React, { FC } from 'react';
import { isUndefined } from 'lodash';
import { Route, Redirect } from 'react-router-dom';

import { FeatureFlags } from 'consts';

import { useFeatureFlagsContext } from 'tools/contexts/FeatureFlagsContext';

type ProtectRouteByFlagProps = {
    path: string;
    flag: FeatureFlags;
    children: JSX.Element | JSX.Element[];
};

export const ProtectRouteByFlag: FC<ProtectRouteByFlagProps> = ({
    path,
    flag,
    children,
}) => {
    const { checkFlag } = useFeatureFlagsContext();
    const shouldBeProtected = checkFlag(flag, true);

    if(isUndefined(shouldBeProtected)) return null;
    if (shouldBeProtected) return <Route path={path}>{children}</Route>;
    return <Redirect to="/" />;    
};
