import React from 'react';
import classNames from 'classnames';

import SVGIcon from 'assets/icons/SVGIcon';
import { ReactComponent as EyeSVG } from 'assets/icons/svg/RVR_header.svg';
import { ReactComponent as CloudsSVG } from 'assets/icons/svg/Cloud_header.svg';
import { ReactComponent as AirplaneSVG } from 'assets/icons/svg/Airplane_header.svg';
import { ReactComponent as ReportSVG } from 'assets/icons/svg/Report_on.svg';
import { ReactComponent as PlusSVG } from 'assets/icons/svg/Generate.svg';
import { ReactComponent as SearchSVG } from 'assets/icons/svg/Magnifying glass_on.svg';
import { ReactComponent as CalendarSVG } from 'assets/icons/svg/Calendar.svg';
import { ReactComponent as ChevronRightSVG } from 'assets/icons/svg/Chevron right.svg';
import { ReactComponent as ChevronLeftSVG } from 'assets/icons/svg/Chevron left.svg';
import { ReactComponent as FirstSVG } from 'assets/icons/svg/First_on.svg';
import { ReactComponent as LastSVG } from 'assets/icons/svg/Last_on.svg';
import { ReactComponent as ChevronUpSVG } from 'assets/icons/svg/Chevron up_on.svg';
import { ReactComponent as ChevronDownSVG } from 'assets/icons/svg/Chevron down_on.svg';
import { ReactComponent as ExportSVG } from 'assets/icons/svg/Export.svg';
import { ReactComponent as SunSVG } from 'assets/icons/svg/Sun.svg';
import { ReactComponent as ArrowUpSVG } from 'assets/icons/svg/arrow_up.svg';
import { ReactComponent as ArrowDownSVG } from 'assets/icons/svg/arrow_down.svg';
import { ReactComponent as ShrinkSVG } from 'assets/icons/svg/Shrink.svg';
import { ReactComponent as EnlargeSVG } from 'assets/icons/svg/Enlarge.svg';
import { ReactComponent as SettingsGearSVG } from 'assets/icons/svg/Settings_header.svg';
import { ReactComponent as CheckboxClickedSVG } from 'assets/icons/svg/Checkbox_clicked.svg';
import { ReactComponent as CheckboxHoverSVG } from 'assets/icons/svg/Checkbox_hover.svg';
import { ReactComponent as CheckboxOffSVG } from 'assets/icons/svg/Checkbox_off.svg';
import { ReactComponent as CheckmarkSVG } from 'assets/icons/svg/Checkmark.svg';
import { ReactComponent as PressureSVG } from 'assets/icons/svg/Pressure_header.svg';
import { ReactComponent as WeatherSVG } from 'assets/icons/svg/weather_light.svg';
import { ReactComponent as AcceptSVG } from 'assets/icons/svg/Accpet.svg';
import { ReactComponent as DeclineSVGLight } from 'assets/icons/svg/Decline.svg';
import { ReactComponent as WarnSVG } from 'assets/icons/svg/Warn.svg';

export type IconNames =
    | 'rvr'
    | 'clouds'
    | 'plane'
    | 'report'
    | 'plus'
    | 'search'
    | 'calendar'
    | 'chevron-right'
    | 'chevron-left'
    | 'first'
    | 'last'
    | 'chevron-up'
    | 'chevron-down'
    | 'export'
    | 'sun'
    | 'arrow-up'
    | 'arrow-down'
    | 'checkbox-clicked'
    | 'checkbox-hover'
    | 'checkbox-off'
    | 'checkmark'
    | 'shrink'
    | 'enlarge'
    | 'gear'
    | 'pressure'
    | 'weather'
    | 'accept'
    | string
    | undefined;

export default function AwosIcon({
    styles,
    iconName,
}: {
    styles: string;
    iconName: IconNames;
}): JSX.Element {
    const icon = (name: IconNames) => {
        switch (name) {
            case 'rvr':
                return (
                    <SVGIcon
                        lightIcon={
                            <EyeSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'clouds':
                return (
                    <SVGIcon
                        lightIcon={
                            <CloudsSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'plane':
                return (
                    <SVGIcon
                        lightIcon={
                            <AirplaneSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'report':
                return (
                    <SVGIcon
                        lightIcon={
                            <ReportSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'plus':
                return (
                    <SVGIcon
                        lightIcon={
                            <PlusSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'search':
                return (
                    <SVGIcon
                        lightIcon={
                            <SearchSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'calendar':
                return (
                    <SVGIcon
                        lightIcon={
                            <CalendarSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'chevron-right':
                return (
                    <SVGIcon
                        lightIcon={
                            <ChevronRightSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'chevron-left':
                return (
                    <SVGIcon
                        lightIcon={
                            <ChevronLeftSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'first':
                return (
                    <SVGIcon
                        lightIcon={
                            <FirstSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'last':
                return (
                    <SVGIcon
                        lightIcon={
                            <LastSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'chevron-up':
                return (
                    <SVGIcon
                        lightIcon={
                            <ChevronUpSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'chevron-down':
                return (
                    <SVGIcon
                        lightIcon={
                            <ChevronDownSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'export':
                return (
                    <SVGIcon
                        lightIcon={
                            <ExportSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'sun':
                return (
                    <SVGIcon
                        lightIcon={
                            <SunSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'arrow-up':
                return (
                    <SVGIcon
                        lightIcon={
                            <ArrowUpSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'arrow-down':
                return (
                    <SVGIcon
                        lightIcon={
                            <ArrowDownSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'checkbox-clicked':
                return (
                    <SVGIcon
                        lightIcon={
                            <CheckboxClickedSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'checkbox-hover':
                return (
                    <SVGIcon
                        lightIcon={
                            <CheckboxHoverSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'checkbox-off':
                return (
                    <SVGIcon
                        lightIcon={
                            <CheckboxOffSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'checkmark':
                return (
                    <SVGIcon
                        lightIcon={
                            <CheckmarkSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'shrink':
                return (
                    <SVGIcon
                        lightIcon={
                            <ShrinkSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'enlarge':
                return (
                    <SVGIcon
                        lightIcon={
                            <EnlargeSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'gear':
                return (
                    <SVGIcon
                        lightIcon={
                            <SettingsGearSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'pressure':
                return (
                    <SVGIcon
                        lightIcon={
                            <PressureSVG
                                className={styles}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'weather':
                return (
                    <SVGIcon
                        lightIcon={
                            <WeatherSVG
                                className={classNames('w-6', styles)}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
                    />
                );
            case 'accept':
                return (
                    <SVGIcon
                        lightIcon={
                            <AcceptSVG
                                className={classNames(styles)}
                                aria-hidden="true"
                                focusable={false}
                                role="img"
                            />
                        }
            />
                );
            case 'decline':
                return (
                    <SVGIcon
                        lightIcon={
                            <DeclineSVGLight
                                role="img"
                                focusable={false}
                                aria-hidden="true"
                                className={classNames(styles)}
                            />
                        }
                    />
                );
            case 'warn':
                return (
                    <SVGIcon
                        lightIcon={
                            <WarnSVG
                                role="img"
                                focusable={false}
                                aria-hidden="true"
                                className={classNames(styles)}
                            />
                        }
                    />
                );                    
            default:
                return <div className={styles}> </div>;
        }
    };

    return icon(iconName);
}
