import { noop } from 'lodash';
import React, { ComponentType, ReactElement, useEffect } from 'react';

import {
    GroupBase,
    components,
    OptionProps,
    MultiValueProps,
    ValueContainerProps,
    DropdownIndicatorProps,
} from 'react-select';

import AwosIcon from 'pages/generic/AwosIcon';

import Checkbox from '../Checkbox';
import { SelectOption, ExtendedSelectMenuProps } from './types';

export const MultiValue: ComponentType<
    MultiValueProps<SelectOption, true, GroupBase<SelectOption>>
> = (multiValueProps) => <>{multiValueProps.children}</>;

export const ValueContainer: ComponentType<
    ValueContainerProps<SelectOption, true, GroupBase<SelectOption>>
> = (valueContainerProps) => {
    const { children } = valueContainerProps;
    const [selectedValues, input] = children as ReactElement[];

    return (
        <components.ValueContainer {...valueContainerProps}>
            <div className="flex truncate items-center text-awos-navy-2 dark:text-awos-white-3">
                {input}
                {!input.props.value && (
                    <div className="truncate w-full">
                        {Array.isArray(selectedValues) ? (
                            selectedValues.map((tag, index) => (
                                <span data-testid={tag.key} key={tag.key}>
                                    {tag}
                                    {index + 1 === selectedValues.length
                                        ? ''
                                        : ', '}
                                </span>
                            ))
                        ) : (
                            <span>{selectedValues}</span>
                        )}
                    </div>
                )}
            </div>
        </components.ValueContainer>
    );
};

export const DropdownIndicator: ComponentType<
    DropdownIndicatorProps<SelectOption, true, GroupBase<SelectOption>>
> = (dropdownIndicatorProps) => (
    <components.DropdownIndicator {...dropdownIndicatorProps}>
        <AwosIcon
            iconName={
                dropdownIndicatorProps.selectProps.menuIsOpen
                    ? 'arrow-up'
                    : 'arrow-down'
            }
            styles="w-2.5"
        />
    </components.DropdownIndicator>
);

export const Option: ComponentType<
    OptionProps<SelectOption, true, GroupBase<SelectOption>>
> = (optionProps) => (
    <components.Option {...optionProps}>
        <div
            data-testid={optionProps.children}
            className="multi-select-option flex items-center"
        >
            <Checkbox
                id={`multi-select-option-${optionProps.children}`}
                dataTestId={`multi-select-option-${optionProps.children}`}
                checked={optionProps.isSelected}
                onChange={noop}
            />
            <span>{optionProps.children}</span>
        </div>
    </components.Option>
);

export const Menu: ComponentType<ExtendedSelectMenuProps> = (menuProps) => {
    const { placement, setMenuPlacement } = menuProps;
    useEffect(() => setMenuPlacement(placement), [placement, setMenuPlacement]);

    return <components.Menu {...menuProps} className={menuProps.placement} />;
};
