import React from 'react';
import { Redirect } from 'react-router';

import { useKeycloak } from 'tools/contexts/KeycloakContext';

const Welcome: React.FunctionComponent = () => {
    const { keycloak } = useKeycloak();

    return (
        <>
            {keycloak?.authenticated ? (
                <Redirect to="/home" />
            ) : (
                window.location.replace(
                    keycloak?.createLoginUrl({
                        redirectUri: `${window.location.origin}/home`,
                    })
                )
            )}
        </>
    );
};

export default Welcome;
