import { UseQueryResult } from '@tanstack/react-query';
import React, { createContext, useContext } from 'react';

import { TableData } from 'pages/generic/Table';
import { useGetData } from 'tools/hooks/getData';
import { WindroseData } from 'pages/generic/Windrose';
import { ReportListData } from 'pages/home/components/reportFooter';
import { TelegramTableElementsList } from '../components/telegramTable';

type HomeContextProps = {
    children: JSX.Element;
};

type HomeData<T> = {
    data: T;
    isSyncFailed: boolean;
};

type HomeDataContextType = {
    rvr: HomeData<TableData> | undefined;
    gas: HomeData<TableData> | undefined;
    clouds: HomeData<TableData> | undefined;
    weather: HomeData<TableData> | undefined;
    pressure: HomeData<TableData> | undefined;
    reportList: HomeData<ReportListData> | undefined;
    windroseLeft: HomeData<WindroseData> | undefined;
    windroseRight: HomeData<WindroseData> | undefined;
    validLastMetReport: HomeData<TelegramTableElementsList> | undefined;
};

const initialContextValue = {
    rvr: undefined,
    gas: undefined,
    clouds: undefined,
    weather: undefined,
    pressure: undefined,
    reportList: undefined,
    windroseLeft: undefined,
    windroseRight: undefined,
    validLastMetReport: undefined,
};

function pick<T>({
    data,
    failureCount,
    isPaused,
}: UseQueryResult<T>): HomeData<T> {
    return { data: data as T, isSyncFailed: isPaused || !!failureCount };
}
const HomeDataContext = createContext<HomeDataContextType>(initialContextValue);

const baseOptions = {
    enabled: true,
    intervalMs: 2000,
};

export const HomeDataContextProvider: React.FunctionComponent<
    HomeContextProps
> = ({ children }: HomeContextProps) => {
    const rvr = useGetData<TableData>({
        ...baseOptions,
        endpoint: '/rvr',
        queryName: 'rvr',
        initialValue: {},
    });

    const gas = useGetData<TableData>({
        ...baseOptions,
        endpoint: '/gas',
        queryName: 'gas',
        initialValue: {},
    });

    const pressure = useGetData<TableData>({
        ...baseOptions,
        endpoint: '/pressure',
        queryName: 'pressure',
        initialValue: {},
    });

    const clouds = useGetData<TableData>({
        ...baseOptions,
        endpoint: '/clouds',
        queryName: 'clouds',
        initialValue: {},
    });

    const weather = useGetData<TableData>({
        ...baseOptions,
        endpoint: '/weather',
        queryName: 'weather',
        initialValue: {},
    });

    const windroseLeft = useGetData<WindroseData>({
        ...baseOptions,
        endpoint: '/wind?rwy=0',
        queryName: 'windroseLeft',
        initialValue: { runway_active: false },
    });

    const windroseRight = useGetData<WindroseData>({
        ...baseOptions,
        endpoint: '/wind?rwy=1',
        queryName: 'windroseRight',
        initialValue: { runway_active: false },
    });

    const validLastMetReport = useGetData<TelegramTableElementsList>({
        ...baseOptions,
        endpoint: '/reports/local',
        queryName: 'validLastMetReport',
        initialValue: [],
    });

    const reportList = useGetData<ReportListData>({
        ...baseOptions,
        endpoint: '/reports?desc_sorting=1&per_page=10&report_type=METAR',
        queryName: 'reportList',
        initialValue: { reports: [] },
    });

    return (
        <HomeDataContext.Provider
            value={{
                rvr: pick<TableData>(rvr),
                gas: pick<TableData>(gas),
                clouds: pick<TableData>(clouds),
                weather: pick<TableData>(weather),
                pressure: pick<TableData>(pressure),
                reportList: pick<ReportListData>(reportList),
                windroseLeft: pick<WindroseData>(windroseLeft),
                windroseRight: pick<WindroseData>(windroseRight),
                validLastMetReport: pick<TelegramTableElementsList>(validLastMetReport),
            }}
        >
            {children}
        </HomeDataContext.Provider>
    );
};

export const useHomeDataContext = (): HomeDataContextType =>
    useContext<HomeDataContextType>(HomeDataContext);
