import { Prompt, useHistory } from 'react-router-dom';
import React, { useState, FC, useEffect } from 'react';

import { PreventNavigationProps, PreventNavigationData } from './types';

export const PreventNavigation: FC<PreventNavigationProps> = ({
    children,
    shouldPrevent,
}) => {
    const history = useHistory();
    const [isBlocked, setBlockState] = useState(shouldPrevent);
    const [preventedLocation, setPreventedLocation] = useState<string>('');

    useEffect(() => {
        setBlockState(shouldPrevent);
    }, [shouldPrevent]);

    const proceedToPreventedLocation = () => {
        setBlockState(false);
        setTimeout(() => history.push(preventedLocation));
    };

    const nestedProps: PreventNavigationData = {
        isBlocked,
        setBlockState,
        preventedLocation,
        setPreventedLocation,
        proceedToPreventedLocation,
    };

    return (
        <>
            <Prompt
                when
                message={({ pathname }) => {
                    setPreventedLocation(pathname);
                    return !isBlocked;
                }}
            />
            {children({ ...nestedProps })}
        </>
    );
};
