import classNames from 'classnames';
import { noop, isUndefined } from 'lodash';
import React, { MouseEventHandler, FC } from 'react';

import AwosIcon, { IconNames } from '../AwosIcon';

type InputProps = {
    testId?: string;
    primary?: boolean;
    pressed?: boolean;
    disabled?: boolean;
    className?: string;
    iconName?: IconNames;
    classNameIcon?: string;
    type?: 'submit' | 'button',
    onClick?: MouseEventHandler<HTMLButtonElement>;
    children?: string | JSX.Element | JSX.Element[];
};

// in case if tailwind does not have clear defined className as a solid string(has it as concatinated string, or string template)
// this className will be treeshaked, and will be missed in build bundle
// that means that it is important to avoid dynamically creating class strings in templates with string concatenation,
// otherwise PurgeCSS won’t know to preserve those classes.
// https://v2.tailwindcss.com/docs/optimizing-for-production
const colorStyles = {
    pr: `
        border-c-button-ligth-prBorder dark:border-c-button-dark-prBorder
        text-c-button-light-prText bg-c-button-light-prBackground
        hover:bg-c-button-light-prHoverBackground active:bg-c-button-light-prActiveBackground
        disabled:bg-c-button-light-prDisabledBackground disabled:text-c-button-light-prDisabledText
        dark:text-c-button-dark-prText dark:bg-c-button-dark-prBackground
        dark:hover:bg-c-button-dark-prHoverBackground dark:active:bg-c-button-dark-prActiveBackground
        dark:disabled:bg-c-button-dark-prDisabledBackground dark:disabled:text-c-button-dark-prDisabledText`,
    sc: `
        border-c-button-ligth-scBorder dark:border-c-button-dark-scBorder
        text-c-button-light-scText bg-c-button-light-scBackground
        hover:bg-c-button-light-scHoverBackground active:bg-c-button-light-scActiveBackground
        disabled:bg-c-button-light-scDisabledBackground disabled:text-c-button-light-scDisabledText
        dark:text-c-button-dark-scText dark:bg-c-button-dark-scBackground
        dark:hover:bg-c-button-dark-scHoverBackground dark:active:bg-c-button-dark-scActiveBackground
        dark:disabled:bg-c-button-dark-scDisabledBackground dark:disabled:text-c-button-dark-scDisabledText`,
    // for now we do not have Pressed primary buttons, so prOn and prOff same as sc
    prOn: `
        hover:bg-c-button-light-scActiveBackground dark:hover:bg-c-button-dark-scActiveBackground
        dark:bg-c-button-dark-scActiveBackground bg-c-button-light-scActiveBackground`,
    prOff: `
        hover:bg-c-button-light-scHoverBackground dark:hover:bg-c-button-dark-scHoverBackground
        dark:bg-c-button-dark-scBackground bg-c-button-light-scBackground`,
    scOn: `
        hover:bg-c-button-light-scActiveBackground dark:hover:bg-c-button-dark-scActiveBackground
        dark:bg-c-button-dark-scActiveBackground bg-c-button-light-scActiveBackground`,
    scOff: `
        hover:bg-c-button-light-scHoverBackground dark:hover:bg-c-button-dark-scHoverBackground
        dark:bg-c-button-dark-scBackground bg-c-button-light-scBackground`,
};

export const Button: FC<InputProps> = ({
    pressed,
    primary,
    children,
    iconName,
    disabled,
    className,
    testId = '',
    classNameIcon,
    type = 'button',
    onClick = noop,
}) => {
    const isPressable = !isUndefined(pressed);
    const styles = {
        icon: `h-4 mr-2 ${disabled ? 'text-white ' : ''}`,
        container: 'h-full relative flex justify-end text-awos-navy-2 dark:text-awos-white-3 ',
        button : classNames(`
            flex items-center h-full p-2 text-sm sm:text-base font-semibold border
            rounded disabled:cursor-not-allowed placeholder-fnt-normal`,
            colorStyles[primary ? 'pr' : 'sc'], 
            isPressable && pressed && colorStyles[primary ? 'prOn' : 'scOn'],
            isPressable && !pressed && colorStyles[primary ? 'prOff' : 'scOff'],
        ),
    };

    return (
        <div className={classNames(styles.container, className)}>
            <button
                type={type}
                onClick={onClick}
                disabled={disabled}
                data-testid={testId}
                className={classNames(styles.button, isPressable && pressed)}
            >
                {iconName ? <AwosIcon
                    iconName={iconName}
                    styles={classNames(styles.icon, classNameIcon)}
                /> : null}                
                {children}
            </button>
        </div>
    );
};