import classNames from 'classnames';
import { memo, FC, ReactNode } from 'react';

import AwosIcon from 'pages/generic/AwosIcon';
import { Roles, NEW_REPORT_ID } from 'consts';
import { Tooltip } from 'pages/generic/tooltip';
import { ProtectFeatureByRole } from 'tools/protectors';
import { SyncFailedSpinner } from 'pages/generic/syncFailedSpinner';
import BasicWindowContainer from 'pages/generic/BasicWindowContainer';
import { useReportNotify } from 'tools/contexts/reportNotificationContext';
import { DEFAULT_REPORT_MODAL_CONFIG } from 'tools/contexts/reportNotificationContext/consts';

const styles = {
    reportIcon: 'inline w-4 py-1 ml-1 mr-5',
    plusIcon: 'inline w-5 h-5 mr-2 mb-1',
    generateButton:
        'font-bold h-full items-center flex px-2 hover:bg-awos-navy-5 dark:hover:bg-awos-black-8',
};

export type ReportGenerationWidgetProps = {
    widgetName: string;
    children?: ReactNode;
    isSyncFailed?: boolean;
};

export const ReportGenerationWidget: FC<ReportGenerationWidgetProps> = memo(
    ({
        children,
        widgetName,
        isSyncFailed = false,
    }: ReportGenerationWidgetProps) => {
        const { reportsQueue, updateReportsQueue, reportPending, isMainTab } =
            useReportNotify();

        return (
            <BasicWindowContainer
                className="report-generation-widget"
                styles={{
                    topBar: 'py-0',
                    container: 'relative',
                }}
                topLeft={
                    <span className="text-sm sm:text-base font-semibold">
                        <AwosIcon
                            iconName="report"
                            styles={styles.reportIcon}
                        />
                        <span>{widgetName}</span>
                    </span>
                }
                topRight={
                    <ProtectFeatureByRole
                        roles={{ included: [Roles.CREATE_REPORTS] }}
                    >
                        <Tooltip
                            title={
                                <div className="text-base">
                                    Report modal already opened or it is not
                                    main tab
                                </div>
                            }
                            childrenOnly={!reportPending && isMainTab.currMain}
                        >
                            <div
                                onClick={() => {
                                    if (!reportPending && isMainTab.currMain) {
                                        updateReportsQueue([
                                            ...reportsQueue,
                                            {
                                                ...DEFAULT_REPORT_MODAL_CONFIG,
                                                reportId: NEW_REPORT_ID,
                                            },
                                        ]);
                                    }
                                }}
                                onKeyPress={() => {}}
                                role="button"
                                tabIndex={0}
                                data-testid="generate-report-button"
                                className={classNames(styles.generateButton, {
                                    'cursor-not-allowed': reportPending,
                                })}
                            >
                                <AwosIcon
                                    iconName="plus"
                                    styles={styles.plusIcon}
                                />
                                <span>GENERATE REPORT</span>
                            </div>
                        </Tooltip>
                    </ProtectFeatureByRole>
                }
            >
                <div className="text-awos-navy-2 dark:text-gray-300 text-left">
                    {children}
                </div>
                <SyncFailedSpinner isSyncFailed={isSyncFailed} />
            </BasicWindowContainer>
        );
    }
);

ReportGenerationWidget.defaultProps = {
    isSyncFailed: false,
    children: null,
};
