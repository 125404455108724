import { FormData, Form, ConvertedForRequestFormData } from './types';

export const convertFormEntries = (
    formData: FormData,
    form: Form
): ConvertedForRequestFormData => {
    return Object.entries(formData).map(([name, value]) => {
        const { data = {} } = (
            form.fields.find(({ id }) => id.name === name) || { id: {} }
        ).id;

        return { data, name, value: value === '' ? null : value };
    });
};
