import React, { FC } from 'react';

import LoadingSpinner from 'pages/generic/LoadingSpinner';

export const SyncFailedSpinner: FC<{ isSyncFailed: boolean }> = ({
    isSyncFailed,
}) =>
    isSyncFailed ? (
        <LoadingSpinner withShadow className="sync-failed-spinner" />
    ) : null;
