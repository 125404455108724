import { Theme, StylesConfig, GroupBase } from 'react-select';

import { SelectOption, SelectProps, MultiSelectProps } from './types';

type MatchValueToSelectOption = (
    options: SelectOption[],
    selectedValue: SelectProps['value']
) => SelectOption | undefined;
export const matchValueToSelectOption: MatchValueToSelectOption = (
    options,
    selectedValue
) => options.find(({ value }) => selectedValue === value);

type MatchValuesToMultiselectOption = (
    options: SelectOption[],
    selectedValues: MultiSelectProps['value']
) => SelectOption[] | undefined;
export const matchValuesToMultiselectOptions: MatchValuesToMultiselectOption = (
    options,
    selectedValues = []
) => {
    return options.filter(({ value }) => {
        return selectedValues.includes(value);
    });
};

const colorScheme = {
    light: {
        optionBackgroundActive: 'rgba(229,229,229,1)',
        optionBackground: '#fff',
        fontColor: 'rgb(18, 58, 100)',
        controlBackground: '#fff',
        controlHoverColor: '#F5F4F4',
        controlBorderColor: 'rgb(209, 208, 208)',
        controlHoverBorderColor: 'rgb(209, 208, 208)',
        openControlBackground: '#E5E5E5',
        indicatorHover: 'rgb(18, 58, 100)',
    },
    dark: {
        optionBackgroundActive: '#0000004D',
        optionBackground: 'rgba(30, 32, 37, 1)',
        fontColor: '#DCE2F2',
        controlBackground: '#0000004D',
        controlHoverColor: '#0000004D',
        controlBorderColor: '#0F0E12',
        controlHoverBorderColor: '#3E404D',
        openControlBackground: '#0000004D',
        indicatorHover: '#DCE2F2',
    },
};

type GetColorTheme = (colorMode: 'light' | 'dark') => (theme: Theme) => Theme;
export const getColorTheme: GetColorTheme = (colorMode) => (theme) => {
    const { optionBackgroundActive } = colorScheme[colorMode];
    return {
        ...theme,
        colors: {
            ...theme.colors,
            primary: optionBackgroundActive,
        },
    };
};

export type GetSelectStyles = (
    colorMode: 'light' | 'dark',
    menuPlacement: string,
    borders?: boolean
) => StylesConfig<SelectOption, true, GroupBase<SelectOption>>;
export const getSelectStyles: GetSelectStyles = (
    colorMode,
    menuPlacement,
    borders = true
) => {
    const {
        fontColor,
        indicatorHover,
        optionBackground,
        controlHoverColor,
        controlBackground,
        controlBorderColor,
        openControlBackground,
        optionBackgroundActive,
        controlHoverBorderColor,
    } = colorScheme[colorMode];
    const isBottomMenu = menuPlacement === 'bottom';

    return {
        option: (styles, { isFocused }) => {
            const borderStyle = borders ? `1px solid ${controlBorderColor}` : 0;
            return {
                ...styles,
                backgroundColor: isFocused
                    ? optionBackgroundActive
                    : optionBackground,
                color: fontColor,
                cursor: isFocused ? 'pointer' : 'default',
                border: borderStyle,
                borderTop: isBottomMenu ? 0 : borderStyle,
                borderBottom: isBottomMenu ? borderStyle : 0,
                padding: '8px 10px',
                textAlign: 'left',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: optionBackgroundActive,
                },
            };
        },
        menu: (styles) => {
            return {
                ...styles,
                boxShadow:
                    '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
                background: optionBackground,
                borderTop: 0,
                marginTop: 0,
                marginBottom: 0,
            };
        },
        menuList: (styles) => {
            return {
                ...styles,
                paddingBottom: 0,
                paddingTop: 0,
            };
        },
        control: (styles, { menuIsOpen, isDisabled }) => {
            let borderRadius: string | number = 4;

            if (menuIsOpen) {
                borderRadius = isBottomMenu ? '4px 4px 0 0' : '0 0 4px 4px';
            }

            return {
                ...styles,
                borderRadius,
                boxShadow: 'none',
                border: borders ? `1.5px solid ${controlBorderColor}` : '0',
                transition: 'none',
                height: 42,
                textAlign: 'left',
                backgroundColor: menuIsOpen
                    ? openControlBackground
                    : controlBackground,
                ':hover': {
                    ...styles[':hover'],
                    transition: 'border-color 400ms',
                    backgroundColor: menuIsOpen
                        ? openControlBackground
                        : controlHoverColor,
                    borderColor: menuIsOpen
                        ? controlBorderColor
                        : controlHoverBorderColor,
                    cursor: isDisabled ? 'default' : 'pointer',
                },
            };
        },
        singleValue: (styles) => {
            return {
                ...styles,
                color: fontColor,
            };
        },
        dropdownIndicator: (styles) => {
            return {
                ...styles,
                color: fontColor,
                padding: '8px 8px 8px 0',
                ':hover': {
                    ...styles[':hover'],
                    color: indicatorHover,
                },
            };
        },
        indicatorSeparator: () => {
            return {
                display: 'none',
            };
        },
        noOptionsMessage: (styles) => {
            return {
                ...styles,
                border: `1px solid ${controlBorderColor}`,
                borderTop: 'none',
                borderRadius: '0 0 3px 3px',
            };
        },
        input: (styles, { value }) => {
            return {
                ...styles,
                color: fontColor,
                margin: 0,
                width: value ? 'auto' : 0,
            };
        },
    };
};
