import React from 'react';
import ToggleInput from 'pages/generic/form/ToggleInput';

import { SettingsInput } from './SettingsInput';
import { SettingsSelect } from './SettingsSelect';
import {
    Field,
    FormRegistering,
    InputAttributes,
    SelectAttributes,
} from '../types';

export const generateComponent = (
    field: Field,
    formRegistering: FormRegistering,
): JSX.Element => {
    switch (field.component_type) {
        case 'input': {
            const inputAttributes: InputAttributes = field.component;
            return (
                <SettingsInput
                    name={field.id.name}
                    inputAttr={inputAttributes}
                    formRegistering={formRegistering}
                />
            );
        }
        case 'checkbox': {
            const inputAttributes: HTMLInputElement = field.component;
            return (
                <ToggleInput
                    options={{
                        id: field.id.name,
                        name: field.id.name,
                        defaultChecked: inputAttributes.checked,
                        isUnique: true,
                    }}
                    formRegistering={formRegistering}
                />
            );
        }
        case 'select': {
            const selectAttributes: SelectAttributes = field.component;
            return (
                <SettingsSelect
                    id={field.id.name}
                    inputAttr={selectAttributes}
                    formRegistering={formRegistering}
                />
            );
        }
        default:
            return <div>Component</div>;
    }
};
