import React, { useEffect, FC, useCallback } from 'react';
import { useQueryParam, ArrayParam, withDefault } from 'use-query-params';

import { MultiSelect, SelectOption } from 'pages/generic/form/select';  

type ReportMultiselectProps = {
    className?: string;
    disabled?: boolean;
};

const options: SelectOption[] = [
    { value: "METAR" , label: "METAR"},
    { value: "MET_REPORT" , label: "MET Report"}
]

export const ReportMultiselect: FC<ReportMultiselectProps> = ({
    className = '',
    disabled = false,
}) => {
    const [queryValue, setQueryValue] = useQueryParam(
        'report_type',
        withDefault(ArrayParam, [])
    );

    useEffect(() => {
        if (queryValue.length === 0) {
            setQueryValue(options.map(({ value }: SelectOption) => value));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

     const onChange = useCallback((queryValues: string[]) => {
        setQueryValue(queryValues.map((value) => String(value)));
    }, [setQueryValue]);

    return (
        <MultiSelect
            disabled={disabled}
            options={options}
            value={queryValue as string[]}
            onChange={onChange}
            className={className}
        /> 
    );
};
