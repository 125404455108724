import axios from 'axios';
import {
    useMutation,
    UseMutationResult,
    UseMutationOptions,
} from '@tanstack/react-query';

import { useKeycloak } from 'tools/contexts/KeycloakContext';

const ENDPOINT_PREFIX = '/api';

function usePostData<
    TData = unknown,
    TError = unknown,
    TVariables = unknown,
    TContext = unknown
>(
    endpoint: string,
    options?: Omit<
        UseMutationOptions<TData, TError, TVariables, TContext>,
        'mutationKey' | 'mutationFn'
    >
): UseMutationResult<TData, TError, TVariables, TContext> {
    const { authHeader } = useKeycloak();
    const mutationResults = useMutation({
        mutationFn: (data) => {
            return axios.post(ENDPOINT_PREFIX + endpoint, data, {
                headers: authHeader,
            });
        },
        ...options,
    });

    return {
        ...mutationResults,
        mutate: (...params: [TVariables]) => {
            if (!mutationResults.isLoading) {
                mutationResults.mutate(...params);
            }
        },
    };
}

export { usePostData };
