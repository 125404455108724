import { noop } from 'lodash';

import { ReportType } from 'consts';

import { ReportModalConfiguration, ReportNotifyContextProps } from './types';

export const TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const DEFAULT_REPORT_MODAL_CONFIG: ReportModalConfiguration = {
    notificationReason: '',
    data: '',
    fields: [],
    special: false,
    mode: 'SEMI-MANUAL',
    notificationTitle: '',
    reportPopupTitle: '',
    reportType: ReportType.METAR,
};

export const DEFAULT_REPORT_NOTIFICATION_CONTEXT: ReportNotifyContextProps = {
    reportsQueue: [],
    setTabToMain: noop,
    websocket: undefined,
    reportPending: false,
    setReportPending: noop,
    updateReportsQueue: noop,
    setReportModalConfig: noop,
    reportModalConfig: DEFAULT_REPORT_MODAL_CONFIG,
    isMainTab: { currMain: false, prevMain: false },
};
