import React from 'react';

type SVGIconProps = {
    lightIcon: React.ReactElement;
    darkIcon?: React.ReactElement;
    darkMode?: boolean;
};

const SVGIcon: React.FunctionComponent<SVGIconProps> = ({
    lightIcon,
    darkIcon,
    darkMode,
}: SVGIconProps) => {
    return <span className="svg">{darkMode ? darkIcon : lightIcon}</span>;
};

SVGIcon.defaultProps = {
    darkIcon: <></>,
    darkMode: false,
};

export default SVGIcon;
