import React from 'react';

export interface ExtendedInputType
    extends React.ComponentPropsWithRef<'input'> {
    dataTestId?: string;
    iconPadding?: boolean;
    hideControls?: boolean;
    componentRef?: React.Ref<HTMLInputElement>;
}

const Input: React.FunctionComponent<ExtendedInputType> = ({
    id,
    name,
    type,
    value,
    onChange,
    disabled,
    minLength,
    maxLength,
    placeholder,
    componentRef,
    step = 'any',
    defaultValue,
    dataTestId = '',
    iconPadding = false,
    hideControls = false,
}: ExtendedInputType): JSX.Element => {
    const styles = {
        searchInput: `w-full py-2 pl-3 ${iconPadding ? 'pr-8' : 'pr-3'} ${
            hideControls ? 'appearance-none' : ''
        } font-normal outline-none border rounded border-c-input-light-border focus:ring ring-awos-blue-3-38 focus:border-c-input-light-borderFocus
         text-c-input-light-text placeholder-awos dark:placeholder-awosDark placeholder-font-normal dark:text-c-input-dark-text dark:bg-c-input-dark-background
        dark:ring-awos-gray-18 dark:border-c-input-dark-border dark:hover:border-c-input-dark-borderHover hover:border-c-input-light-borderHover
        dark:focus:border-c-input-dark-borderFocus readonly:bg-c-input-light-backgroundDisabled dark:readonly:bg-c-input-dark-backgroundDisabled readonly:text-c-input-light-textDisabled
        dark:readonly:text-c-input-dark-textDisabled`,
    };

    return (
        <input
            id={id}
            name={name}
            step={step}
            type={type}
            value={value}
            ref={componentRef}
            disabled={disabled}
            minLength={minLength}
            maxLength={maxLength}
            onChange={onChange}
            data-testid={dataTestId}
            placeholder={placeholder}
            defaultValue={defaultValue}
            className={styles.searchInput}
        />
    );
};

export default Input;
