import axios from 'axios';
import React, { useEffect } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useKeycloak } from 'tools/contexts/KeycloakContext';

const ENDPOINT_PREFIX = '/api';

export type GetDataReturn<T> = {
    data: T;
    isPending: boolean;
    fetchData: () => void;
    isSyncFailed: boolean;
    setData: React.Dispatch<React.SetStateAction<T>>;
};

export type GetDataOptions<T> = {
    initialValue: T;
    retry?: boolean;
    enabled: boolean;
    endpoint: string;
    queryName: string;
    intervalMs?: number;
    clearOnError?: boolean;
};

function useGetData<T>(options: GetDataOptions<T>): UseQueryResult<T> {
    const { authHeader } = useKeycloak();
    const {
        retry = false,
        enabled,
        endpoint,
        queryName,
        intervalMs,
        initialValue,
        clearOnError,
    } = options;

    const query = useQuery({
        queryKey: [queryName],
        queryFn: () => {
            return axios
                .get(ENDPOINT_PREFIX + endpoint, {
                    headers: authHeader,
                })
                .then(({ data }: { data: T }) => data);
        },
        retry,
        enabled,
        initialData: initialValue,
        refetchInterval: intervalMs,
    });

    useEffect(() => {
        if (clearOnError && (query.isPaused || query.isError)) {
            query.remove();
        }
    }, [query.isError, query.isPaused, clearOnError]); // eslint-disable-line react-hooks/exhaustive-deps

    return query;
}

export { useGetData };
